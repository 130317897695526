import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { apiUrl } from "../../api/ApiService";
const token = localStorage.getItem('token');

export const getThemesCourses = createAsyncThunk('get/getThemesCourses', async ({ id }, thunkAPI) => {


  try {
    const { data } = await axios.get(`${apiUrl}/api/v1/groupThemes/${id}`, {
      headers: {
        Authorization: `Bearer` + token

      }

    })
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error);
    }


    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
})

export const getStudentTask = createAsyncThunk('get/getStudentTask', async ({ id, theme_id }, thunkAPI) => {



  try {
    const { data } = await axios.get(`${apiUrl}/api/v1/studentTasks/${theme_id}/${id}`, {
      headers: {
        Authorization: `Bearer` + token

      }

    })

    if (data.error) {
      return thunkAPI.rejectWithValue(data.error);
    }
    if (data.answer_content === null || 'null') {
      thunkAPI.dispatch(responceTask(''))

    } else {
      thunkAPI.dispatch(responceTask(data.answer_content))

    }

    if (!data.content) {
      return {
        ...data, content: {
          blocks: [
            {
              type: 'header',
              data: {
                text: 'Заголовок',
                level: 1,
              },
            },
          ],
        }
      }
    }


    return { ...data, content: JSON.parse(data.content) }
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
})
export const complitedStudentTask = createAsyncThunk('post/complitedStudentTask', async ({ id_theme, id_task, answer_content }, thunkAPI) => {

  const token = localStorage.getItem('token');

  try {
    const { data } = await axios.post(`${apiUrl}/api/v1/studentTasks`, {
      group_theme_id: id_theme, task_id: id_task, answer_content,
    }, {
      headers: {
        Authorization: `Bearer` + token

      }
    })
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error)
    }


    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const themesCoursesSlise = createSlice({
  name: "themes-corses",
  initialState: {
    taskResponseStudent: '',
    themes: [],
    studentTask: null,
    complitedTask: null
  },
  reducers: {
    responceTask(state, { payload }) {
      state.taskResponseStudent = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(complitedStudentTask.fulfilled, (state, { payload }) => {
      state.studentTask = payload.status ? { ...state.studentTask, status: payload.status } : { ...state.studentTask, status: null }
    })

    builder.addCase(getThemesCourses.fulfilled, (state, { payload }) => {
      state.themes = payload
    })
    builder.addCase(getThemesCourses.pending, (state, { payload }) => {
      state.themes = []
    })


    builder.addCase(getStudentTask.fulfilled, (state, { payload }) => {
      state.taskResponseStudent = payload.answer_content
      state.studentTask = payload
    })

  }
})
export const { responceTask } = themesCoursesSlise.actions

export default themesCoursesSlise.reducer