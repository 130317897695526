import classNames from 'classnames';
import React from 'react';

const UserList = ({messageAlert, active}) => {
  
  return (
    <li className={classNames('user-list', {
      messageAlert: messageAlert,
      active: active
    })}>
      <span className="user-avatar">
        Т
      </span>
      <span className="user-list_div">
        <p className="user-name">
          <b>Тестиев Сайти</b>
          <span className='time'>12:30</span>
        </p>
        <p className="last-message">
          <span className='message'>Ну учитывая...</span>
          <span className="new-message_count"></span>
        </p>
      </span>
    </li>
  );
};

export default UserList;