import React from 'react';
import './AddCourses.scss';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Select from '../../UI/Select/Select';
import Textarea from '../../UI/Textarea/Textarea';
import { message } from 'antd';

import Switch from '../../UI/Switch/Switch';
import calendarClock from '../../assets/img/icons/calendar-clock.svg';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { apiUrl } from '../../api/ApiService';
import axios from 'axios';

import { Controller, useForm } from 'react-hook-form';

const AddCourses = () => {
  const path = useLocation();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = React.useState({
    full_name: '',
    short_name: '',
    category_id: [1],
    course_format: '',
    module_quantity: '1',
    course_start: '',
    course_end: '',
    description: '',
    tracking: 1,
    show_conditions: 1,
    access: 1,
    experience: 1,
  });
  const [tracking, setTracking] = React.useState(1);
  const [categoryCourses, setCategoryCourses] = React.useState([]);

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [courseArray, setCourseArray] = React.useState([]);
  const { id } = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = { Authorization: `Bearer ` + token };

        const categoryResponse = await axios.get(`${apiUrl}/api/v1/courses/create`, {
          headers: headers,
        });
        setCategoryCourses(categoryResponse.data);

        if (id) {
          const courseResponse = await axios.get(`${apiUrl}/api/v1/courses/course/${id}`, {
            headers: headers,
          });
          const courseData = courseResponse.data;
          setFormData({
            full_name: courseData.full_name,
            short_name: courseData.short_name,
            category_id: courseData.category_id || [],
            course_format: courseData.course_format,
            module_quantity: courseData.module_quantity,
            course_start: courseData.course_start,
            course_end: courseData.course_end,
            description: courseData.description,
            tracking: courseData.tracking,
            show_conditions: courseData.show_conditions,
            access: courseData.access,
          });
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };
    fetchData();
  }, [id]);

  const handleSubmitForm = async (data) => {
    try {
      const categoryIds = Array.isArray(data.category_id) ? data.category_id : [data.category_id];

      const mergedData = {
        ...formData,
        ...data,
        tracking: tracking,
        id: id,
        category_id: categoryIds,
      };
      const endpoint = id ? `${apiUrl}/api/v1/courses?_method=put` : `${apiUrl}/api/v1/courses`;

      const headers = { Authorization: `Bearer` + token };

      await axios.post(endpoint, mergedData, {
        headers: headers,
      });

      navigate('/');
      message.success('Курс успешно добавлен');
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        console.log('Response Data:', error.response.data);
      }
      message.error('Курс не получилось добавить');
    }
  };

  React.useEffect(() => {
    for (const key in formData) {
      setValue(key, formData[key]);
    }
  }, [formData, setValue]);

  React.useEffect(() => {
    let arr = [];
    if (categoryCourses.max_modules) {
      for (let i = 1; i <= Number(categoryCourses.max_modules); i++) {
        arr.push(i);
        setCourseArray(arr);
      }
    }
  }, [categoryCourses]);

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)} action={id ? '' : `${apiUrl}/api/v1/courses`}>
      <div className="add-courses">
        <div className="add-courses_form">
          <div className="add-courses_general">
            <h3 className="add-courses_title">Общее</h3>
            <div className="course-name">
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <label>
                    Полное название курса <span>*</span>
                    <Input type="text" placeholder="Название" {...field}></Input>
                    {errors.full_name && <span className="error">{errors.full_name.message}</span>}
                  </label>
                )}
                rules={{ required: 'Это поле обязательно' }}
              />

              <Controller
                name="short_name"
                control={control}
                render={({ field }) => (
                  <label>
                    Краткое название курса <span>*</span>
                    <Input type="text" placeholder="Название" {...field} />
                    {errors.short_name && (
                      <span className="error">{errors.short_name.message}</span>
                    )}
                  </label>
                )}
                rules={{ required: 'Это поле обязательно' }}
              />
            </div>

            <div className="course-format">
              <Controller
                name="category_id"
                control={control}
                defaultValue={1}
                render={({ field }) => (
                  <label>
                    Категория курса
                    <Select className="selector-custom" {...field} multiple>
                      {categoryCourses.categories &&
                        categoryCourses.categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                    </Select>
                    {errors.category_id && (
                      <span className="error">{errors.category_id.message}</span>
                    )}
                  </label>
                )}
                rules={{ required: 'Выберите категорию курса' }}
              />

              <Controller
                name="course_format"
                control={control}
                render={({ field }) => (
                  <label>
                    Формат курса
                    <Select {...field}>
                      <option value="" disabled selected>
                        Формат
                      </option>
                      {categoryCourses.formats &&
                        categoryCourses.formats.map((format, index) => (
                          <option key={index} value={format}>
                            {format}
                          </option>
                        ))}
                    </Select>
                    {errors.course_format && (
                      <span className="error">{errors.course_format.message}</span>
                    )}
                  </label>
                )}
                rules={{ required: 'Выберите формат курса' }}
              />
            </div>
            <Controller
              name="module_quantity"
              control={control}
              render={({ field }) => (
                <label>
                  Кол-во модулей
                  {!path.pathname.split('/').includes('change-course') && (
                    <Select {...field}>
                      {courseArray.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                  {errors.module_quantity && (
                    <span className="error">{errors.module_quantity.message}</span>
                  )}
                </label>
              )}
              rules={{ required: 'Выберите количество модулей' }}
            />

            <div className="course_data-holding">
              <Controller
                name="course_start"
                control={control}
                render={({ field }) => (
                  <label>
                    Дата начала курса
                    <Input type="date" {...field} />
                    {errors.course_start && (
                      <span className="error">{errors.course_start.message}</span>
                    )}
                    <img src={calendarClock} alt="calendarClock" />
                  </label>
                )}
                rules={{ required: 'Выберите дату начала курса' }}
              />

              <Controller
                name="course_end"
                control={control}
                render={({ field }) => (
                  <label>
                    Дата окончания курса
                    <Input type="date" placeholder="Укажите дату" {...field} />
                    {errors.course_end && (
                      <span className="error">{errors.course_end.message}</span>
                    )}
                    <img src={calendarClock} alt="calendarClock" />
                  </label>
                )}
                rules={{ required: 'Выберите дату окончания курса' }}
              />
            </div>

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <label>
                  Описание курса
                  <Textarea name="description" placeholder="Текст" {...field} />
                </label>
              )}
            />
          </div>

          <div className="add-courses_access add-courses_fulfillment-tracking">
            <h3 className="add-courses_title">Отслеживание выполнения</h3>
            <p>
              Включить отслеживание выполнения
              <Switch
                checked={tracking === 1}
                onChange={(event) => setTracking(event.target.checked ? 1 : 0)}
              />
            </p>

            <p>
              Показать условия выполнения активного элемента
              <Switch
                checked={tracking === 1}
                onChange={(event) => setTracking(event.target.checked ? 1 : 0)}
              />
            </p>
          </div>
          <div className="add-courses_access add-courses_give-access">
            <h3 className="add-courses_title">Доступ</h3>
            <p>
              Открыть доступ
              <Switch
                checked={tracking === 1}
                onChange={(event) => setTracking(event.target.checked ? 1 : 0)}
              />
            </p>
          </div>
          <div className="add-courses_buttons">
            <Button
              onClick={handleSubmit}
              additionallyClass="add-courses_button add-courses_button-next"
              buttonColor="green"
              type="submit">
              Продолжить
            </Button>

            <Link style={{ textDecoration: 'none' }} to="/">
              <button className="cancel">Отмена</button>
            </Link>
          </div>
          <p className="footnote">
            <span>*</span> Обязательные для заполнения поля в этой форме помечены звездочкой
          </p>
        </div>
      </div>
    </form>
  );
};

export default AddCourses;
