import React from "react";
import "./Switch.scss";

const Switch = ({ checked = false, onChange, value, name }) => {
  let checkboxRef = React.useRef(null);

  // зачем? если можно checked поместить в <input checked={checked} /> без эффекта и рефа
  React.useEffect(() => {
    if (checked) {
      checkboxRef.current.checked = true;
    }
  }, [checked]);

  return (
    <label className="switch">
      <input
        onChange={onChange}
        name={name}
        value={value}
        ref={checkboxRef}
        type="checkbox"
      />
      <span></span>
    </label>
  );
};

export default Switch;
