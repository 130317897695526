import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { apiUrl } from "../../../api/ApiService";


export const getGroupModule = createAsyncThunk('get/getGroupModule', async (params, thunkAPI) => {

  const token = localStorage.getItem('token');


  try {
    const { data } = await axios.get(`${apiUrl}/api/v1/groupModules/groupModule/${2}`, {
      headers: {
        Authorization: `Bearer ` + token,
      }
    })
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error)
    }


    return data
  } catch (error) {

  }
})
export const getStudentTask = createAsyncThunk('get/getStudentTask', async (params, thunkAPI) => {

  const { id_theme, id_task } = params
  const token = localStorage.getItem('token');


  try {
    const { data } = await axios.get(`${apiUrl}/api/v1/studentTasks/${id_theme}/${id_task}`, {
      headers: {
        Authorization: `Bearer ` + token,

      }
    })
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error)
    }


    const resultData = { ...data, content: JSON.parse(data.content) }
    return resultData
  } catch (error) {

  }
})


const siteMapSlice = createSlice({
  name: 'siteMap',
  initialState: {
    modules: {},
    task: null,
    tasks: null,
    loading: false,
    error: false,
  },
  reducers: {
    setTasks(state, { payload }) {
      if (payload === null) state.tasks = null
      if (state.tasks) state.tasks = [...state.tasks, ...payload]
      else state.tasks = payload
    },
    editTasks(state, { payload }) {
      state.tasks = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupModule.fulfilled, (state, { payload }) => {

      state.modules = payload
    })
    builder.addCase(getStudentTask.fulfilled, (state, { payload }) => {
      state.task = payload
    })
  }
})

export const { setTasks, editTasks } = siteMapSlice.actions

export default siteMapSlice.reducer