import React from 'react';
import Courses from '../Courses/Courses';
import CourseOfCard from '../../components/CourseOfCard/CourseOfCard';

const AllCourses = () => {
  return (
    <div>
      <Courses />
      <CourseOfCard />
    </div>
  );
};
export default AllCourses;
