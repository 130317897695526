import React from 'react';

import './checkTask.scss';

import arrow from '../../assets/img/icons/arrow.svg';
import axios from 'axios';
import { apiUrl } from '../../api/ApiService';

import ReactSelect from '../../UI/ReactSelect/ReactSelect';
import CustomSelector from '../../UI/CustomSelector/CustomSelector';

const CheckTask = () => {
  const [selectGroup, setSelectGroup] = React.useState('Winx');
  const [magazineType, setMagazineType] = React.useState('Проверка заданий');
  const [selectModule, setSelectModule] = React.useState('Второй модуль');
  const [selectTheme, setSelectTheme] = React.useState('Карта сайта');

  const [magazineTasks, setMagazineTasks] = React.useState([]);
  const [magazinGroups, setMagazineGroups] = React.useState([]);
  const [magazineThemes, setMagazineThemes] = React.useState([]);
  const [magazineModules, setMagazineModules] = React.useState([]);
  const [magazineStudent, setMagazineStudent] = React.useState([]);

  const token = localStorage.getItem('token');

  const [closeMagazine, setCloseMagazine] = React.useState(true);
  const magazines = [
    { id: 1, name: 'Посещаемость', active: true },
    { id: 2, name: 'Проверка заданий', active: false },
  ];

  React.useEffect(() => {
    const handleSubmit = async () => {
      const requestData = {
        selectGroup: magazinGroups.find((group) => group.name === selectGroup)?.id || null,
        magazineType: magazines.find((magazine) => magazine.name === magazineType)?.id || null,
        selectModule: magazineModules.find((module) => module.name === selectModule)?.id || null,
        selectTheme: magazineThemes.find((theme) => theme.name === selectTheme)?.id || null,
      };
    };
    handleSubmit();
  }, [selectGroup, magazineType, selectModule, selectTheme]);

  const isClosed = () => {
    setCloseMagazine(!closeMagazine);
  };

  React.useEffect(() => {
    const fetchMagazine = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/gradebook`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setMagazineStudent(data.student_tasks);
        setMagazineGroups(data.groups);
        setMagazineModules(data.group_modules);
        setMagazineThemes(data.themes);
        setMagazineTasks(data.tasks);
      } catch (error) {
        console.log('Не получилось вернуть журнал');
      }
    };
    fetchMagazine();
  }, []);

  return (
    <div className="general-magazine-attendance">
      <div
        style={{
          padding: '12px',
          background: '#fff',
          borderRadius: '28px 12px 12px 12px',
        }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <ReactSelect options={magazines} changeFunction={setMagazineType} value={magazineType}>
            Журнал: <span>{magazineType}</span>
          </ReactSelect>

          <div className="general-magazine-attendance_information-tabs">
            <ReactSelect
              options={magazinGroups}
              changeFunction={setSelectGroup}
              value={selectGroup}>
              Группа: <span>{selectGroup}</span>
            </ReactSelect>

            <ReactSelect
              options={magazineModules}
              changeFunction={setSelectModule}
              value={selectModule}>
              Модуль: <span>{selectModule}</span>
            </ReactSelect>

            <ReactSelect
              value={selectTheme}
              options={magazineThemes}
              changeFunction={setSelectTheme}>
              Тема: <span>{selectTheme}</span>
            </ReactSelect>
          </div>
        </div>

        <div className="general-magazine-attendance_board">
          <div className="general-magazine-attendance_board-columns">
            <div className="general-magazine-attendance_board-column name-column">
              <div className="general-magazine-attendance_board-title">
                <span>ФИО</span>
                <div className="icons">
                  <img onClick={isClosed} src={arrow} alt="arrow" />
                </div>
              </div>
              {closeMagazine ? (
                <ul className="student-lists">
                  {magazineStudent.map((name, index) => (
                    <div className="student-list" key={index}>
                      <span>{name.name}</span>
                    </div>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </div>

            <div className="attendance-columns">
              {magazineTasks.map((task) => (
                <div className="general-magazine-attendance_board-column attendance-column">
                  <div className="general-magazine-attendance_board-title">
                    <span> {task.title}</span>
                    <div className="icons">
                      <img src={arrow} alt="arrow" />
                    </div>
                  </div>

                  <ul className="attendance-lists">
                    <CustomSelector />
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckTask;
