import React from 'react';
import './RightColumnCard.scss'
import classNames from 'classnames';

const RightColumnCard = ({children, classCard}) => {
  return (
    <div className={classNames('right-column-card', `${classCard}`)}>
      {children}
    </div>
  );
};
export default RightColumnCard;