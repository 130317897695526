import React from 'react';
import './Sent.scss';
import mailCheck from '../../assets/img/icons/mail-check.svg';
import gmail from '../../assets/img/icons/gmail.svg';
import mail from '../../assets/img/icons/mail.svg';
import authorizationImg from '../../assets/img/Programming-pana 1.svg';

import Modal from '../../components/Modal/Modal';
import logoCB from '../../assets/img/icons/logo CB.svg';
import close from '../../assets/img/icons/close.svg';
import PrivacyPolicy from '../Authorization/PrivacyPolicy/PrivacyPolicy';
import { Link, useParams } from 'react-router-dom';

const Sent = () => {
  let modal = React.useRef(null);
  const { email } = useParams();

  function showModal(e) {
    e.preventDefault();
    modal.current.style.top = '40px';
  }

  function hideModal() {
    modal.current.style.top = '150%';
  }

  return (
    <div className="authorization">
      <img src={authorizationImg} className="authorization_img" alt="img" />

      <div className="sent-block">
        <div className="sent-img">
          <img src={mailCheck} alt="mailCheck" />
        </div>

        <h2>Мы отправили письмо!</h2>
        <p className="sent_description">Перейдите по ссылке в письме и восстановите пароль</p>
        <Link to="/" className="sent_link back">
          Назад
        </Link>

        <div className="links-to-email">
          <Link to={`mailto:${email}`}>
            <img src={gmail} alt="gmail" />
          </Link>
          <Link to={`mailto:${email}`} target="_blank" className="mail">
            <img src={mail} alt="mail" />
          </Link>
        </div>

        <div className="privacy-policy">
          <p className="copyright">© 2021-2023 ООО «AIS». Все права защищены.</p>
          <p className="site-rules">
            <a onClick={(e) => showModal(e)} href="/">
              Правила сайта
            </a>
            <span> | </span>
            <a onClick={(e) => showModal(e)} href="/">
              Политика конфиденциальности
            </a>
          </p>
        </div>
      </div>

      <Modal modal={modal}>
        <div className="modal-body">
          <img src={logoCB} alt="" />
          <div onClick={hideModal} className="close">
            <img src={close} alt="" />
          </div>

          <PrivacyPolicy />
        </div>
      </Modal>
    </div>
  );
};

export default Sent;
