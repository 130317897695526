import React from 'react';
import { useSelector } from 'react-redux';

import './MyCourses.scss';
import Loader from '../../../components/Loader/Loader';
import CourseCard from './CourseCard/CourseCard';

import LoaderSkeleton from '../../../components/LoaderSkeleton';
import MyLoader from '../../.././components/CourseOfCard/Loader';

const MyCourses = () => {
  const groupCourses = useSelector((state) => state.courses.groupCourses);
  const isLoading = useSelector((state) => state.courses.isLoading);
  const [initialLoad, setInitialLoad] = React.useState(true);

  React.useEffect(() => {
    if (groupCourses.length > 0) {
      setInitialLoad(false);
    }
  }, [groupCourses]);

  return (
    <>
      <h3>Мои курсы</h3>
      <div className="my-courses">
        {isLoading && initialLoad ? (
          <Loader />
        ) : (
          <>
            {groupCourses.slice(0, 3).map((item, i) => {
              return <CourseCard key={i} obj={item} />;
            })}
            {groupCourses.length > 3 && ''}
            {groupCourses.length === 0 && !isLoading && !initialLoad && (
              <p>У вас нет доступных курсов</p>
            )}
            {groupCourses.length < 3 && !isLoading && !initialLoad && (
              <LoaderSkeleton count={3 - groupCourses.length} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MyCourses;
