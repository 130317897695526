import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";


// этот код тоже нужен. вдруг не сможем восстановить строчку. а когда будет нужна, просто скопируем и вставим
// const token = document.cookie.match(/token=(.+?)(;|$)/) || [];

const token = localStorage.getItem('token');


export const fetchGroupCourses = createAsyncThunk(
  "courses/fetchGroupCourses",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${apiUrl}/api/v1/groupCourses`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    groupCourses: [],
  },


  extraReducers: (builder) => {
    builder.addCase(fetchGroupCourses.fulfilled, (state, { payload }) => {
      state.groupCourses = payload;
      state.isLoading = false;
    });
  },
});

export default coursesSlice.reducer;
