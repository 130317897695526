import React from 'react';
import './Avatar.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Avatar = ({ children, width1, width2, width3, imageSrc }) => {
  return (
    <Link
      to=""
      className={classNames('avatar', {
        width1: width1,
        width2: width2,
        width3: width3,
      })}>
      {imageSrc ? (
        <div className="avatar-image-container">
          <img src={imageSrc} className="avatar-image" />
        </div>
      ) : (
        children
      )}
    </Link>
  );
};

export default Avatar;
