import React, { useEffect, useRef } from 'react';
import arrow from '../../assets/img/icons/arrow.svg';
import classNames from 'classnames';

import './ReactSelect.scss';
import { useDispatch } from 'react-redux';
import { getModule } from '../../redux/editor/editor.slice';

const ReactSelect = ({ children, options, changeFunction, setModuleEditor, className }) => {
  const dispatch = useDispatch();
  const currentUrl = window.location.href.split('/')[3];
  const [isOpen, setIsOpen] = React.useState(false);
  const selectRef = useRef(null);
  const setActive = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    changeFunction({
      label: option.title || option.name || option.full_name || option.order || option.label,
      order: option?.order,
      id: option.id || option.value,
      isOpen,
    });

    setIsOpen(false);
    if (currentUrl === 'find-references') {
      dispatch(getModule({ id: option.id }));
      setModuleEditor && setModuleEditor(option.order);
    }
  };

  const handleDocumentClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div
      ref={selectRef}
      className={classNames('react-select', className)}
      onClick={(e) => setActive(e)}>
      {children}
      {isOpen && (
        <div className="select-options">
          {options?.map((option) => (
            <div
              key={option.id || option.value}
              className="select-option"
              onClick={() => handleOptionClick(option)}>
              {currentUrl === 'find-references'
                ? option.order
                : option.name || option.title || option.full_name || option.order || option.label}
            </div>
          ))}
        </div>
      )}
      {
        <img
          src={arrow}
          className={isOpen ? 'react-select_img activeSelector' : 'react-select_img'}
          alt="arrow"
        />
      }
    </div>
  );
};
export default ReactSelect;
