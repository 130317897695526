import React from 'react';
import logoCB from '../../assets/img/icons/logo CB.svg'
import errorPage from '../../assets/img/Error_page-404.svg'
import './ErrorPage.scss'
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate()
  
  return (
    <div className='error-page'>
      <div className="error-page_logo">
        <img src={logoCB} alt="logoCB" />
      </div>
      <div className="error-page_img">
        <img src={errorPage} alt="errorPage" />
      </div>
      <button onClick={() => navigate('/')} className="error-page_btn_to_main">На главную</button>
    </div>
  );
};

export default ErrorPage;