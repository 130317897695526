import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={270}
    height={180}
    viewBox="0 0 270 180"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}>
    <rect x="7" y="37" rx="6" ry="6" width="56" height="56" />
    <rect x="67" y="40" rx="5" ry="5" width="182" height="20" />
    <rect x="67" y="66" rx="5" ry="5" width="182" height="20" />
    <rect x="7" y="116" rx="3" ry="3" width="101" height="13" />
    <rect x="146" y="119" rx="3" ry="3" width="101" height="13" />
    <rect x="5" y="156" rx="0" ry="0" width="22" height="21" />
    <rect x="224" y="155" rx="0" ry="0" width="22" height="21" />
  </ContentLoader>
);

export default MyLoader;
