import React from 'react';
import './noConnect.scss';

const NoConnect = () => {
  return (
    <div className="noConnect-items">
      <div className="img">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 2L22 22"
            stroke="#F6222E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.78204 5.78223C4.38831 6.50286 3.27709 7.67084 2.62673 9.09872C1.97638 10.5266 1.82459 12.1316 2.19573 13.6561C2.56688 15.1805 3.43943 16.5361 4.67336 17.5053C5.90728 18.4744 7.43103 19.0009 9.00004 19.0002H17.5C17.9428 19.0005 18.3832 18.9355 18.807 18.8072"
            stroke="#F6222E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.532 16.4998C21.8724 15.8137 22.0321 15.052 21.9958 14.2869C21.9596 13.5218 21.7287 12.7786 21.3249 12.1277C20.9212 11.4769 20.3579 10.9398 19.6886 10.5674C19.0193 10.195 18.2659 9.99968 17.5 9.99982H15.71C15.3275 8.7191 14.5867 7.57447 13.575 6.70096C12.5632 5.82745 11.3228 5.26149 10 5.06982"
            stroke="#F6222E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <span>Нет соедениения</span>
    </div>
  );
};

export default NoConnect;
