import React from 'react';
import './RightColumn.scss';
import DesingUi from '../../../assets/img/DesignUI.svg';
import Developer from '../../../assets/img/Developer.svg';
import Avatar from '../../../components/Avatar/Avatar';
import RightColumnCard from './RightColumnCard/RightColumnCard';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import axios from 'axios';
import { apiUrl } from '../../../api/ApiService';

const RightColumn = ({ userInfo }) => {
  const [lastActivity, setLastActivity] = React.useState([]);
  const [visits, setVisits] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get(`${apiUrl}/api/v1/userVisit`, {
        headers: {
          Authorization: 'Bearer: ' + token,
        },
      })
      .then((d) => {
        setVisits(d.data);
      });
  }, []);

  React.useState(() => {
    const token = localStorage.getItem('token');

    const getActivity = async () => {
      const { data } = await axios.get(`${apiUrl}/api/v1/lastActivity`, {
        Authorization: `Bearer ` + token,
      });
      setLastActivity(data);
    };
    getActivity();
  }, []);

  function getUserName(name) {
    if (name) {
      const [_, lastName] = name.split(' ');
      return lastName[0];
    }

    return '';
  }

  function setImage(title) {
    title = title.toLowerCase();
    switch (title) {
      case 'дизайн':
        return <img src={DesingUi} alt="DesingUi" />;
      case 'фронтэнд':
        return <img src={Developer} alt="Developer" />;
      case 'бекэнд':
        return <img src={Developer} alt="Developer" />;
      default:
        <h2>Нет курсов</h2>;
    }
  }

  function visitFunction(obj, index) {
    switch (true) {
      case obj.visited == 0:
        return (
          <div key={obj.id} className="activity-date absent">
            {index + 1}
          </div>
        );
      case obj.visited == null:
        return (
          <div key={obj.id} className="activity-date">
            {index + 1}
          </div>
        );
      case obj.visited && !obj.today:
        return (
          <div key={obj.id} className="activity-date was">
            {index + 1}
          </div>
        );
      case obj.visited && obj.today:
        return (
          <div key={obj.id} className="activity-date present">
            {index + 1}
          </div>
        );
      default:
        break;
    }
    // return <div className="activity-date">{index+1}</div>
  }

  function returnName() {
    if (userInfo.name) {
      let name = '';
      for (let i = 0; i < userInfo.name.split(' ').length; i++) {
        name += `${
          userInfo.name.split(' ')[i].slice(0, 1).toUpperCase() +
          userInfo.name.split(' ')[i].slice(1)
        } `;
      }
      return name.trim();
    }
  }

  return (
    <div className="right-column">
      <RightColumnCard classCard="user-profile">
        <div className="progress">
          <ProgressBar />
          <Avatar>
            {userInfo.image_path ? (
              <img
                className="img-upload"
                src={`https://school.code06.ru/storage/app/public/${userInfo.image_path}`}
              />
            ) : (
              <p>{userInfo.name?.split(' ')[1].slice(0, 1)}</p>
            )}
          </Avatar>
          {userInfo.level === 15 ? (
            <>
              <p className="level">{userInfo.level} ур.</p>
              <p className="experience">Max level</p>
            </>
          ) : (
            <>
              <p className="level">{userInfo.level} ур.</p>
              <p className="experience">
                <span>{userInfo.experience}</span>/{userInfo.next_level_experience}XP
              </p>
            </>
          )}
        </div>
        <p className="name">
          {returnName()}
          <span className="position">{userInfo?.role?.nameRu}</span>
        </p>
        <div className="activity">
          <div className="top-section">
            <b>Активность</b>
            <span>{visits?.streak} дня подряд</span>
          </div>
          <div className="bottom-section">
            <div className="activity-dates">
              {visits?.visits?.map((item, i) => {
                return visitFunction(item, i);
              })}
            </div>
          </div>
        </div>
      </RightColumnCard>

      <RightColumnCard classCard="last-active">
        <p className="right-column-card_title">Последняя активность</p>

        <div className="last-active_courses">
          {lastActivity.map((item) => (
            <div key={item.id} className="last-active_course">
              <div className="last-active_course-img">{setImage(item.categories[0].title)}</div>
              <div className="last-active_course-text">
                <div>
                  <span className="course-name">{item.full_name.slice(0, 15)}...</span>
                  <span className="course-description">{item.short_name}</span>
                </div>
                <span className="last-active_time">{item?.date}</span>
              </div>
            </div>
          ))}
        </div>
      </RightColumnCard>
    </div>
  );
};

export default RightColumn;
