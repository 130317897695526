import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactSelect from '../../UI/ReactSelect/ReactSelect';
import { useSelector, useDispatch } from 'react-redux';
import plusSvg from '../../assets/img/icons/plus.svg';
import { useDebouncedCallback } from 'use-debounce';
import { LoadingOutlined, CheckCircleTwoTone } from '@ant-design/icons';

import {
  setAddThemeInputValue,
  openThemeAccordion,
  setActiveTask,
  getModule,
  appendTheme,
  appendTask,
  getTask,
  deleteTask,
  deleteTheme,
  editTheme,
  updateTheme,
  themeInputActive,
  editTask,
} from '../../redux/editor/editor.slice';

import Pointer from '../../components/Pointer';
import TaskEditor from '../../components/TaskEditor';
import Accordion from '../../components/Accordion';
import editIcon from '../../assets/img/icons/pen-square.svg';
import deleteIcon from '../../assets/img/icons/trash-2.svg';
import { useLocation, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { getModulesList } from '../../redux/module/module.slice';
import { Space, Spin } from 'antd';
import NoConnect from '../../UI/NoConnect';

import './FindReferences.scss';
import { setDescription, updateModuleDesc } from '../../redux/updateModuleEditor';

const FindReferences = () => {
  let courseId = null;
  const { id: moduleId } = useParams();

  const location = useLocation();
  const dispatch = useDispatch();
  const title = useSelector((state) => state.descModuleInEditor.title);
  const addThemeInputValue = useSelector((state) => state.editor.addThemeInputValue);
  const module = useSelector((state) => state.editor.module);
  const openedAccordionThemeIds = useSelector((state) => state.editor.openedAccordionThemeIds);
  const activeTask = useSelector((state) => state.editor.activeTask);
  const tasks = useSelector((state) => state.editor.tasks);
  const modulesList = useSelector((state) => state.modulesList.modulesList);
  const inputActive = useSelector((state) => state.editor.inputActive);
  const isLoading = useSelector((state) => state.editor).isTasksEditLoading;
  const [hoveredId, setHoveredId] = useState('');
  const [pointerValue, setPointerValue] = useState(null);
  const [moduleEditor, setModuleEditor] = useState(module?.order);

  const themeRef = useRef(null);
  courseId = module.course_id;

  const handleAddTask = (theme_id) => {
    const theme = module.themes.find((th) => th.id === theme_id);
    if (theme) {
      const newTask = {
        theme_id: theme_id,
        experience: '0',
        blocks: [
          {
            type: 'header',
            data: {
              text: 'Заголовок',
              level: 1,
            },
          },
        ],
      };

      dispatch(appendTask({ newTask: newTask }));
      // dispatch(setActiveTask(newTask));
    }
  };

  const handleDeleteTask = (id) => dispatch(deleteTask(id));
  const handleEditTask = (id) => {
    if (activeTask?.id === String(id)) return;
    dispatch(getTask({ id }));
  };
  const handleDeleteTheme = (id) => dispatch(deleteTheme({ id }));
  const handleEditTitleClick = (id) => {
    dispatch(
      editTheme(
        module.themes.map((item) => {
          if (item.isEdit) {
            dispatch(
              updateTheme({
                id: item.id,
                order: item.order,
                title: item.title,
              }),
            );
          }
          if (item.id === id) {
            return { ...item, isEdit: !item.isEdit };
          }

          return { ...item, isEdit: false };
        }),
      ),
    );
  };
  const handleTitleChange = (event, id) => {
    const newArr = module?.themes.map((item) =>
      item.id === id ? { ...item, title: event.target.value } : item,
    );
    dispatch(editTheme(newArr));
  };
  const handleChangeModule = (param) => {};
  const handleAddTheme = () => {
    if (!addThemeInputValue) {
      dispatch(themeInputActive(!inputActive));
      return;
    }
    if (inputActive) {
      dispatch(appendTheme({ title: addThemeInputValue, module_id: Number(module?.id) }));
      dispatch(themeInputActive(!inputActive));
    }
    dispatch(themeInputActive(!inputActive));
  };
  const handleBlurAccordion = () => {
    dispatch(
      editTheme(
        module.themes.map((item) => {
          if (item.isEdit) {
            dispatch(
              updateTheme({
                id: item.id,
                order: item.order,
                title: item.title,
              }),
            );
          }
          return { ...item, isEdit: false };
        }),
      ),
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (inputActive) {
        dispatch(appendTheme({ title: addThemeInputValue, module_id: Number(moduleId) }));
        dispatch(themeInputActive(!inputActive));
      }
    }
  };

  const hadleEditPoints = () => {
    const obj = tasks.find((item) => Number(item.id) === Number(activeTask?.id));
    dispatch(
      editTask({
        ...obj,
        experience: pointerValue,
      }),
    );
    setPointerValue(activeTask.experience);
  };

  const updateModule = useDebouncedCallback(() => {
    dispatch(updateModuleDesc({ id: module.id, title, order: module.order }));
  }, 1000);

  React.useEffect(() => {
    !modulesList && dispatch(getModulesList({ id: module.course_id }));
    dispatch(getModule({ id: moduleId }));
    setModuleEditor(module?.order);
  }, [dispatch, location.pathname, moduleId, courseId]);

  return (
    <div className="root">
      <div className="loadingContainer">
        <div className="loading">
          {isLoading === 'succes' && (
            <>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Spin indicator={<CheckCircleTwoTone twoToneColor="#52c41a" />} size="small">
                  <div className="content" />
                </Spin>
              </Space>
              <span className="txt">Сохраненo</span>
            </>
          )}
          {isLoading === 'loading' && (
            <>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Spin indicator={<LoadingOutlined />} size="small">
                  <div className="content" />
                </Spin>
              </Space>
              <span className="txt">Сохранение</span>
            </>
          )}
          {isLoading === 'error' && <NoConnect />}
        </div>
      </div>

      <div className="items">
        <div className="left-section">
          <div className="select">
            <ReactSelect
              setModuleEditor={setModuleEditor}
              changeFunction={handleChangeModule}
              options={modulesList}>
              <span className="text-module">Модуль:</span>
              <span className="order-mudule">{moduleEditor}</span>
            </ReactSelect>
          </div>
          <div className="description-module">
            <TextField
              value={title}
              onChange={(e) => {
                dispatch(setDescription(e.target.value));
                updateModule();
              }}
              className="text-field"
              id="outlined-multiline-static"
              multiline
              rows={2}
              placeholder="Описание модуля"
            />
          </div>
          <div className="accordion-theme-and-task">
            <h3 className="theme-and-tasks">Темы и задания</h3>
            {module?.themes?.map(({ id, title, isEdit, tasks }) => {
              return (
                <Accordion
                  onMouseDown={(e) => e.preventDefault()}
                  onBlur={handleBlurAccordion}
                  key={id}
                  rightSectionDelete={
                    <button className={'deleteIcon'} onClick={() => handleDeleteTheme(id)}>
                      <img src={deleteIcon} alt="deleteIcon" />
                    </button>
                  }
                  rightSectionMove={
                    <button className={'addTaskBtn'} onClick={() => handleAddTask(id)}>
                      <img src={plusSvg} alt="editIcon" />
                    </button>
                  }
                  rightSectionEdit={
                    <button
                      className={'editButton'}
                      ref={themeRef}
                      onClick={() => {
                        handleEditTitleClick(id);
                      }}>
                      <img src={editIcon} alt="editIcon" />
                    </button>
                  }
                  onToggle={() => {
                    if (openedAccordionThemeIds.includes(id))
                      dispatch(
                        openThemeAccordion(openedAccordionThemeIds.filter((item) => item !== id)),
                      );
                    else dispatch(openThemeAccordion([...openedAccordionThemeIds, id]));
                  }}
                  isOpened={openedAccordionThemeIds.includes(id)}
                  id={id}
                  isTitleEdit={isEdit}
                  onChangeTitle={handleTitleChange}
                  title={title}>
                  <ul>
                    {tasks?.map((task) => {
                      if (id === task.theme_id) {
                        return (
                          <li
                            onClick={() => handleEditTask(task.id)}
                            className={`contentTask ${
                              Number(task.id) === Number(activeTask?.id) && 'activeTask'
                            } `}
                            onMouseEnter={() => setHoveredId(task.id)}
                            onMouseLeave={() => setHoveredId('')}
                            key={task.id}>
                            <span className="task">{task.title}</span>

                            {task.id === hoveredId && (
                              <div className="buttons-actions">
                                <button
                                  className="edit-task-btn"
                                  onClick={() => handleEditTask(task.id)}>
                                  <img src={editIcon} alt="close" />
                                </button>
                                <button
                                  className="delete-task-btn"
                                  onClick={() =>
                                    handleDeleteTask({ task_id: task.id, theme_id: id })
                                  }>
                                  <img src={deleteIcon} alt="close" />
                                </button>
                              </div>
                            )}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Accordion>
              );
            })}
            {inputActive && (
              <input
                autoFocus
                className="add-theme-input"
                type="text"
                onKeyDown={handleKeyDown}
                value={addThemeInputValue}
                onChange={(e) => dispatch(setAddThemeInputValue(e.target.value))}
              />
            )}
            <div className="addThemeButton" onClick={() => handleAddTheme()}>
              <div className="plus-img">
                <img src={plusSvg} alt="" />
              </div>
              <span>Добавить тему</span>
            </div>
          </div>
          {activeTask && (
            <Pointer
              pointerValue={pointerValue}
              activeTaskPoint={activeTask}
              hadleEditPoints={hadleEditPoints}
              setPointerValue={setPointerValue}
            />
          )}
        </div>

        <div className="text-editor">
          {activeTask && (
            <TaskEditor
              setPointerValue={setPointerValue}
              activeTask={activeTask}
              pointerValue={pointerValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default FindReferences;
