import React from 'react';
import './AdminGroups.scss';

import edit from '../.././assets/img/icons/pen-square.svg';
import cart from '../../assets/img/icons/cart.svg';
import plusWhite from '../../assets/img/icons/plusWhite.svg';
import userk from '../../assets/img/users-2.svg';

import { message } from 'antd';

import axios from 'axios';
import { apiUrl } from '../../api/ApiService';

import { NavLink } from 'react-router-dom';
import { Button, Input, Modal, Space } from 'antd';
import ReactSelect from 'react-select';
import IconSearch from '../../assets/img/icons/iconSearch';

const AdminGroups = () => {
  const [listUser, setListUser] = React.useState([]);
  const [allGroups, setAllGroups] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [teacher, setTeacher] = React.useState([]);

  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const [selectedTeacher, setSelectedTeacher] = React.useState(null);
  const [selectedCount, setSelectedCount] = React.useState(0);
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);
  const [editableGroups, setEditableGroups] = React.useState({});
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [groupName, setGroupName] = React.useState('');
  const [courses, setCourses] = React.useState([]);

  const openCreateGroupModal = () => {
    setIsCreateGroupModalVisible(true);
  };

  const closeCreateGroupModal = () => {
    setIsCreateGroupModalVisible(false);
  };

  const token = localStorage.getItem('token');

  const handleCreateGroup = async () => {
    try {
      const { data } = await axios.post(
        `${apiUrl}/api/v1/groups`,
        {
          name: groupName,
          teacher_id: selectedTeacher.value,
          course_id: selectedCourse.value,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
      setListUser([...listUser, data]);
      message.success('Группа успешно добавлена');

      closeCreateGroupModal();
    } catch (error) {
      console.log('Error creating group', error);
      message.error('Не удалось добавить группу');
    }
  };

  const handleEditGroupName = (groupId, currentName) => {
    setEditableGroups((prevGroups) => ({
      ...prevGroups,
      [groupId]: currentName,
    }));
  };

  const handleGroupNameUpdate = async (groupId, newName) => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/groups/update`,
        {
          group_id: groupId,
          name: newName,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );

      if (response.status === 200) {
        setEditableGroups((prevGroups) => ({
          ...prevGroups,
          [groupId]: null,
        }));

        setListUser((prevList) =>
          prevList.map((user) => (user.id === groupId ? { ...user, name: newName } : user)),
        );
      }
    } catch (error) {
      console.log('Error updating group name', error);
    }
  };

  const handleDeleteGroup = (groupId) => {
    Modal.confirm({
      title: 'Подтверждение удаления группы',
      content: 'Вы уверены, что хотите удалить эту группу?',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk: () => confirmDeleteGroup(groupId),
    });
  };
  const confirmDeleteGroup = async (groupId) => {
    try {
      await axios.post(
        `${apiUrl}/api/v1/groups?_method=delete`,
        { id: groupId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );

      message.success('Пользователь успешно удален из группы');
      setListUser((prevList) => prevList.filter((group) => group.id !== groupId));
      setSelectedUserIds((prevIds) => prevIds.filter((id) => id !== groupId));
      setSelectedCount((prevCount) => Math.max(0, prevCount - 1));
    } catch (error) {
      console.log('Error deleting group', error);
    }
  };

  const handleDeleteSelectedUsers = async () => {
    Modal.confirm({
      title: 'Вы уверены, что хотите удалить выбранные группы?',
      onOk: async () => {
        try {
          await Promise.all(
            selectedUserIds.map((userId) =>
              axios.post(
                `${apiUrl}/api/v1/groups?_method=delete`,
                { id: userId },
                {
                  headers: {
                    Authorization: 'Bearer ' + token,
                  },
                },
              ),
            ),
          );

          const updatedList = listUser.filter((user) => !selectedUserIds.includes(user.id));
          setListUser(updatedList);

          setSelectedUserIds([]);
          setSelectedCount(0);
          message.success('Группа успешно удалена');
        } catch (error) {
          console.log('Error deleting users', error);
        }
      },
      onCancel: () => {},
    });
  };
  React.useEffect(() => {
    const fetchUserList = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/groups?q=${searchQuery}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setListUser(data.groups);
      } catch (error) {
        console.log('Error', error);
      }
    };
    fetchUserList();
  }, [searchQuery]);

  React.useEffect(() => {
    const fetchUserList = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/groups`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setAllGroups(data.groups_quantity);
        setListUser(data.groups);
      } catch (error) {
        console.log('Error', error);
      }
    };
    fetchUserList();
  }, []);

  React.useEffect(() => {
    const fetchCourses = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/courses`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        setCourses(data);
      } catch (error) {
        console.log('Error fetching courses', error);
      }
    };
    fetchCourses();
  }, []);
  React.useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get(`${apiUrl}/api/v1/users?role=teacher`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        const users = response.data.data.users.map((user) => ({
          label: user.name,
          value: user.id,
          user: user,
        }));
        setTeacher(users);
      })
      .catch((error) => {
        console.error('Error fetching users', error);
      });
  }, []);

  return (
    <div className="table">
      <div className="block">
        <div className="header-button">
          <Input
            style={{ background: '#f0f0f0', border: '0px', width: '280px' }}
            placeholder="Быстрый поиск"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            prefix={<IconSearch />}
          />
          <Space wrap>
            <Button type="primary" onClick={() => openCreateGroupModal()}>
              <img src={plusWhite} alt="Add Group" />
              Добавить группу
            </Button>
          </Space>
        </div>

        <table className="table-groups">
          <thead>
            <tr className="tr">
              <th className="th" style={{ width: '44px' }}>
                <input
                  type="checkbox"
                  checked={selectAllChecked}
                  onChange={() => {
                    setSelectAllChecked(!selectAllChecked);
                    if (!selectAllChecked) {
                      const allUserIds = listUser.map((user) => user.id);
                      setSelectedUserIds(allUserIds);
                      setSelectedCount(allUserIds.length);
                    } else {
                      setSelectedUserIds([]);
                      setSelectedCount(0);
                    }
                  }}
                />
              </th>
              <th className="th">
                Группы
                <span className="th-group">{allGroups ? `${allGroups} групп` : '0 групп'}</span>
              </th>
              <th className="th">Участников</th>
              <th className="th">Курс</th>
              <th className="th">Действия</th>
            </tr>
          </thead>
          <tbody className="lists-users_group">
            <div className="table-scroll-x ">
              {listUser.map((obj) => {
                return (
                  <tr className="tr" key={obj.id}>
                    <td className="td" style={{ width: '44px', marginLeft: '0' }}>
                      <input
                        type="checkbox"
                        checked={selectedUserIds.includes(obj.id)}
                        onChange={() => {
                          const updatedSelectedUserIds = selectedUserIds.includes(obj.id)
                            ? selectedUserIds.filter((userId) => userId !== obj.id)
                            : [...selectedUserIds, obj.id];

                          setSelectedUserIds(updatedSelectedUserIds);
                          setSelectedCount(updatedSelectedUserIds.length);
                        }}
                      />
                    </td>
                    <td className="td td-name">
                      {editableGroups[obj.id] ? (
                        <input
                          value={editableGroups[obj.id]}
                          onChange={(e) =>
                            setEditableGroups((prevGroups) => ({
                              ...prevGroups,
                              [obj.id]: e.target.value,
                            }))
                          }
                          onBlur={() => handleGroupNameUpdate(obj.id, editableGroups[obj.id])}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleGroupNameUpdate(obj.id, editableGroups[obj.id]);
                            }
                          }}
                        />
                      ) : (
                        <p style={{ marginLeft: '12px', width: '180px', whiteSpace: 'nowrap' }}>
                          <NavLink
                            style={{ textDecoration: 'none', color: 'black' }}
                            to={`/out-user/${obj.id}`}>
                            {obj.name.slice(0, 35)}...
                          </NavLink>
                        </p>
                      )}
                    </td>
                    <td className="td td-user_count">{obj.user_count} </td>
                    <td className="td td-cource_name">{obj.cource_name}</td>
                    <td className="td td-action">
                      <img
                        src={edit}
                        alt="Edit"
                        onClick={() => handleEditGroupName(obj.id, obj.name)}
                      />
                      <NavLink style={{ height: '24px' }} to={`/out-user/${obj.id}`}>
                        <img src={userk} alt="User" />
                      </NavLink>
                      <img src={cart} alt="Delete" onClick={() => handleDeleteGroup(obj.id)} />
                    </td>
                  </tr>
                );
              })}
            </div>
          </tbody>
        </table>

        <hr className="hr-info" />
        <div className="setting-down">
          <p>Выделено: {selectedCount}</p>
          <div className="button-groupa">
            <button
              style={{ color: 'red', marginLeft: '600px' }}
              onClick={handleDeleteSelectedUsers}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M2.5 5H17.5"
                  stroke="#D80914"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.8332 5V16.6667C15.8332 17.5 14.9998 18.3333 14.1665 18.3333H5.83317C4.99984 18.3333 4.1665 17.5 4.1665 16.6667V5"
                  stroke="#D80914"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.6665 4.99984V3.33317C6.6665 2.49984 7.49984 1.6665 8.33317 1.6665H11.6665C12.4998 1.6665 13.3332 2.49984 13.3332 3.33317V4.99984"
                  stroke="#D80914"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.3335 9.1665V14.1665"
                  stroke="#D80914"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.6665 9.1665V14.1665"
                  stroke="#D80914"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Удалить
            </button>
          </div>
        </div>
        <Modal
          title="Создание группы"
          width={600}
          visible={isCreateGroupModalVisible}
          onCancel={closeCreateGroupModal}
          onOk={closeCreateGroupModal}
          footer={[
            <Button key="cancel" onClick={closeCreateGroupModal}>
              Отмена
            </Button>,
            <Button key="create" type="primary" onClick={handleCreateGroup}>
              Создать
            </Button>,
          ]}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}>
            <Input
              style={{ width: '240px', height: '40px' }}
              placeholder="Название группы"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
            <div className="modal-selectors">
              <ReactSelect
                options={courses.map((course) => ({ value: course.id, label: course.full_name }))}
                value={selectedCourse}
                onChange={setSelectedCourse}
                placeholder="Курс"
              />
            </div>

            <div className="modal-selectors">
              <ReactSelect
                options={teacher}
                value={selectedTeacher}
                onChange={setSelectedTeacher}
                placeholder="Преподаватель"
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AdminGroups;
