import React from 'react';
import hackcoins from '../../../assets/img/icons/hackcoins.svg';
import cart from '../../../assets/img/icons/shopCart.svg';
import noPhoto from '../../../assets/img/icons/No photo.svg';
import check from '../../../assets/img/icons/check.svg';

import './ShopCard.scss';
import { apiUrl } from '../../../api/ApiService';

const ShopCard = ({ obj, index }) => {
  const [inCart, setInCart] = React.useState(false);
  let card = React.useRef(null);
  React.useEffect(() => {
    card.current.addEventListener('mouseover', (e) => {
      const bigImgElement = card.current.querySelector('.big-img');
      if (bigImgElement) {
        if (obj.images[1]) {
          card.current.querySelector('.big-img').src = noPhoto;
          card.current.querySelector('.price').style.background = '#e4e2e2';
          card.current.querySelector('.mini-card').style.background = '#F0F0F0';
          card.current.querySelector('.mini-card').style.background = '#F0F0F0';
          card.current.querySelector('.mini-card').style.border = '2px solid #fff';
        } else {
          card.current.querySelector('.big-img').src = noPhoto;
        }
      }
    });

    card.current.addEventListener('mouseout', (e) => {
      const bigImgElement = card.current.querySelector('.big-img');
      if (bigImgElement) {
        if (obj.images[1]) {
          card.current.querySelector('.big-img').src = noPhoto;
          card.current.style.background = '#fff';
          card.current.querySelector('.price').style.background = '#F9F9F9';
          card.current.querySelector('.mini-card').style.background = '#fff';
          card.current.querySelector('.mini-card').style.border = '2px solid #F0F0F0';
        } else {
          card.current.querySelector('.big-img').src = noPhoto;
        }
      }
    });
  }, []);

  function addInCart() {
    setInCart(true);
  }

  return (
    <div ref={card} className="card">
      {obj.images[1] && (
        <>
          <div className="mini-card">{obj.images[1] && <img src={noPhoto} alt="" />}</div>
        </>
      )}

      <div className="card_img">
        {obj.images[1] ? (
          <img className="big-img" src={noPhoto} alt="" />
        ) : (
          <>
            <img src={noPhoto} alt="noPhoto" />
          </>
        )}
      </div>
      <div className="card_category">{obj.category}</div>
      <div className="card_title">{obj.title}</div>
      <div className="card_description">
        Количество на складе: <span>{obj.quantity}</span> шт.
      </div>
      <div className="to-cart">
        <div className="price">
          <img src={hackcoins} alt="hackcoins" />
          <span>{obj.price}</span>
        </div>
        <div onClick={(e) => addInCart()} className={inCart ? 'in-cart check' : 'in-cart'}>
          <img src={inCart ? check : cart} alt="cart" />
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
