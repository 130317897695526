export function addStatusIcon(status) {

  switch (status) {
    case 'completed':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.33337 18.3334H15C15.4421 18.3334 15.866 18.1578 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V6.25002L12.0834 1.66669H5.00004C4.55801 1.66669 4.13409 1.84228 3.82153 2.15484C3.50897 2.4674 3.33337 2.89133 3.33337 3.33335V6.66669"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 2V7H16"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 12.5L4.16667 14.1666L7.5 10.8333"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'check':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.33337 18.3334H15C15.4421 18.3334 15.866 18.1578 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V6.25002L12.0834 1.66669H5.00004C4.55801 1.66669 4.13409 1.84228 3.82153 2.15484C3.50897 2.4674 3.33337 2.89133 3.33337 3.33335V5.83335"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6666 1.66669V6.66669H16.6666"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.16663 14.1667C4.49493 14.1667 4.82002 14.102 5.12333 13.9764C5.42665 13.8507 5.70225 13.6666 5.93439 13.4345C6.16654 13.2023 6.35069 12.9267 6.47632 12.6234C6.60196 12.3201 6.66663 11.995 6.66663 11.6667C6.66663 11.3384 6.60196 11.0133 6.47632 10.71C6.35069 10.4067 6.16654 10.1311 5.93439 9.89892C5.70225 9.66677 5.42665 9.48262 5.12333 9.35699C4.82002 9.23135 4.49493 9.16669 4.16663 9.16669C3.50358 9.16669 2.8677 9.43008 2.39886 9.89892C1.93002 10.3678 1.66663 11.0036 1.66663 11.6667C1.66663 12.3297 1.93002 12.9656 2.39886 13.4345C2.8677 13.9033 3.50358 14.1667 4.16663 14.1667Z"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 15L6.25 13.75"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'wrong':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.33337 18.3334H15C15.4421 18.3334 15.866 18.1578 16.1786 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V6.25002L12.0834 1.66669H5.00004C4.55801 1.66669 4.13409 1.84228 3.82153 2.15484C3.50897 2.4674 3.33337 2.89133 3.33337 3.33335V6.66669"
            stroke="#F6222E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6666 1.66669V6.66669H16.6666"
            stroke="#F6222E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 10.4167L6.66667 14.5834"
            stroke="#F6222E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66667 10.4167L2.5 14.5834"
            stroke="#F6222E"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case null:
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.33337 11.25V3.33335C3.33337 2.89133 3.50897 2.4674 3.82153 2.15484C4.13409 1.84228 4.55801 1.66669 5.00004 1.66669H12.0834L16.6667 6.25002V16.6667C16.6667 17.1087 16.4911 17.5326 16.1785 17.8452C15.866 18.1578 15.4421 18.3334 15 18.3334H10.4167"
            stroke="#B7B7B7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6666 1.66669V6.66669H16.6666"
            stroke="#B7B7B7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.68337 10.5083C8.84589 10.3458 9.03881 10.2169 9.25114 10.1289C9.46348 10.041 9.69105 9.99573 9.92087 9.99573C10.1507 9.99573 10.3783 10.041 10.5906 10.1289C10.8029 10.2169 10.9959 10.3458 11.1584 10.5083C11.3209 10.6708 11.4498 10.8638 11.5377 11.0761C11.6257 11.2884 11.671 11.516 11.671 11.7458C11.671 11.9756 11.6257 12.2032 11.5377 12.4155C11.4498 12.6279 11.3209 12.8208 11.1584 12.9833L6.62504 17.5L3.33337 18.3333L4.15837 15.0416L8.68337 10.5083Z"
            stroke="#B7B7B7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      break;
  }
}