import React from 'react';
import './LoaderSkeleton.scss';

const LoaderSkeleton = () => {
  return (
    <div className="loader-courses">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none">
        <path
          d="M20 5H10C7.23858 5 5 7.23858 5 10V20C5 22.7614 7.23858 25 10 25H20C22.7614 25 25 22.7614 25 20V10C25 7.23858 22.7614 5 20 5Z"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35 5C37.75 5 40 7.25 40 10V20C40 22.75 37.75 25 35 25"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50 5C52.75 5 55 7.25 55 10V20C55 22.75 52.75 25 50 25"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25 45H12.5C8.25 45 5 41.75 5 37.5V35"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 52.5L25 45L17.5 37.5"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50 35H40C37.2386 35 35 37.2386 35 40V50C35 52.7614 37.2386 55 40 55H50C52.7614 55 55 52.7614 55 50V40C55 37.2386 52.7614 35 50 35Z"
          stroke="#B7B7B7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>Курс не назначен</p>
    </div>
  );
};

export default LoaderSkeleton;
