import classNames from 'classnames';
import React from 'react';
import arrow from '../../../assets/img/icons/arrow.svg'

const FaqAccordion = ({obj}) => {
  const [openAccordion, setOpenAccordion] = React.useState(false)

  function openAccordionChangeFunction() {
    setOpenAccordion(!openAccordion)
  }

  return (
    <div onClick={openAccordionChangeFunction} className="faq-accordion">
      <div className="close">
        <img src={arrow} className={classNames('close-img', {
          active: openAccordion
        })} alt="arrow" />
      </div>

      <div className="faq-accordion_question">
        {obj.question}
      </div>
      {openAccordion && <hr />}
      <div className={classNames('faq-accordion_answer', {
        active: openAccordion
      })}>
        <ul>
          {obj.answer && obj.answer.map((item, i) => {
            return <li key={i}>{item}</li>
          })}
        </ul>
      </div>
    </div>
  );
};

export default FaqAccordion;