import React from 'react';
import './TabCard.scss';
import classNames from 'classnames';

import { addStatusIcon } from './addStatusIcon';
import { useNavigate } from 'react-router-dom';
import { courseComplited } from './tab-cards.utils';
import { getStudentModule } from '../../../../redux/courses/tabCard';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

const TabCard = ({ obj }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function addClass(index) {
    if (index < obj.completed_total) {
      return `module-count_span module-count_span passed`;
    } else if (index === obj.completed_total) {
      return `module-count_span module-count_span current`;
    } else {
      return `module-count_span module-count_span`;
    }
  }

  const onTabClick = () => {
    if (obj.id === null) {
      message.error('Модуль не начат');
      return;
    }

    navigate(`/site-map/${obj.id}`);
    dispatch(getStudentModule({ id: obj.id }));
  };

  const canNavigate = obj.completed_total >= obj.order - 1;

  return (
    <div
      className={classNames('tab-card', `${obj.status == null ? 'disabled' : ''}`)}
      onClick={onTabClick}
      style={{ cursor: canNavigate ? 'pointer' : 'default' }}>
      <div className="course-status">
        <p className="tab-card_title">Модуль {obj.order}</p>
        {addStatusIcon(obj.status)}
      </div>
      <p className="tab-card_description">{obj.title ? obj.title : ''}</p>
      <div className="tab-card_progress">
        <div className="tab-card_progress-diagram">
          {new Array(obj.theme_quantity).fill(0).map((_, index) => (
            <span key={index} className={addClass(index)}></span>
          ))}
        </div>
        <p className="tab-card_progress-description">
          <span className="module-count">
            {obj.completed_total ? obj.completed_total : 0} / {obj.theme_quantity} Темы
          </span>
          <span className="prosent">
            <span>{courseComplited(obj)}</span>%
          </span>
        </p>
      </div>
    </div>
  );
};

export default TabCard;
