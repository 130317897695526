import React from "react";
import ContentLoader from "react-content-loader";

export const RatingHeader = () => {};

const RatingSkeleton = ({ ...rest }) => (
  <ContentLoader height="100%" width="100%" viewBox="0 0 2100 970" {...rest}>
    <rect x="0" y="0" rx="20" ry="10" width="100%" height="80" />
    <rect x="0" y="90" rx="20" ry="20" width="100%" height="1250" />
  </ContentLoader>
);

RatingSkeleton.metadata = {
  name: "Didier Munezero",
  github: "didiermunezero",
  description: "Skeleton loader for rating component",
  filename: "RatingSkeleton",
};

export default RatingSkeleton;
