import axios from "../core/axios";

import { apiUrl } from "./ApiService";

const token = localStorage.getItem('token');
// async function getUserRating() {
//   const { data } = await axios.get(`${apiUrl}/api/v1/rating/user`);

//   return data;
// }

async function getUserList() {
  const { data } = await axios.get(`${apiUrl}/api/v1/users/user`);

  return data;
}


async function getNotificationIsNew() {
  const { data } = await axios.get(`${apiUrl}/api/v1/notifications/isNew`, {
    headers: {
      Authorization: `Bearer ` + token,
    },
  });

  return data;
}


// async function getNotifications(token) {
//   const { data } = await axios.get(`${apiUrl}/api/v1/notifications`);
//   return data;
// }
// async function getTasks() {
//   const { data } = await axios.get(`${apiUrl}/api/v1/taskblocks/1`);

//   return data;
// }

export const userService = {
  // getUserRating,
  getUserList,
  getNotificationIsNew,
  // getNotifications,
  // getTasks,
};
