import React, { useEffect } from 'react';
import { TextInput } from '@mantine/core';
import { useIMask } from 'react-imask';
import { editTask } from '../../redux/editor/editor.slice';

import { useSelector, useDispatch } from 'react-redux';

import './pointer.scss';

const Pointer = ({ activeTaskPoint, setPointerValue }) => {
  const dispatch = useDispatch();

  const module = useSelector((state) => state.editor.module);
  const theme = module?.themes?.find(
    (theme) => Number(theme.id) === Number(activeTaskPoint.theme_id),
  );
  const task = theme?.tasks.find((task) => Number(task.id) === Number(activeTaskPoint.id));
  useEffect(() => {
    setPointerValue(activeTaskPoint.experience);
    return () => {
      setPointerValue(null);
    };
  }, []);
  const { ref: pointerRef, value: pointerMaskValue } = useIMask({
    mask: '000000000000000000000000000000000000000000 XP',
    blocks: {
      '000000000000000000000000000000000000000000': {
        mask: Number,
        thousandsSeparator: '',
      },
    },
    lazy: false,
  });

  return (
    <div className="reward-quest">
      <label className="point" htmlFor="point">
        Награда за задание
      </label>

      <TextInput
        defaultValue={activeTaskPoint.experience}
        classNames={{ input: 'reward-point' }}
        ref={pointerRef}
        onChange={() => {
          setPointerValue(pointerMaskValue.replace(/[^\d]/g, ''));
          dispatch(
            editTask({
              ...task,
              experience: Number(pointerMaskValue.replace(/[^\d]/g, '')),
            }),
          );
        }}
      />
    </div>
  );
};

export default Pointer;
