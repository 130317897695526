import React from 'react';
import './Header.scss';

import arrowBack from '../../assets/img/icons/arrow-back.svg';

import UserInfo from '../../components/UserInfo/UserInfo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import axios from 'axios';
import { apiUrl } from '../../api/ApiService';
import { getCurrentToken } from '../../functions/function';

const Header = () => {
  let location = useLocation();
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const path = location.pathname;
  const [currentUser, setCurrentUser] = React.useState({});

  React.useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get(`${apiUrl}/api/v1/users/user`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      })
      .then((data) => {
        setCurrentUser(data.data);
      });
  }, []);

  const titleObject = {
    '/': [
      {
        title: `С возвращением, ${
          currentUser.name
            ? currentUser.name.split(' ')[1].slice(0, 1).toUpperCase() +
              currentUser.name.split(' ')[1].slice(1)
            : ''
        } 👋`,
      },
    ],
    '/faq': [
      {
        title: 'FAQ',
      },
    ],
    '/courses': [
      {
        title: 'Курсы 🎨',
      },
    ],
    '/site-map': [
      {
        title: 'Модули',
      },
    ],
    '/settings': [
      {
        title: 'Настройки ⚙️',
      },
    ],
    '/ranking-table': [
      {
        title: 'Таблица рейтинга 🏁',
      },
    ],
    '/shop': [
      {
        title: 'Магазин 🛒',
      },
    ],
    '/chat': [
      {
        title: 'Чат 💬',
      },
    ],
    '/magazine': [
      {
        title: 'Общий журнал 📔',
      },
    ],
    '/add-user': [
      {
        title: 'Добавление пользователя',
      },
    ],
    '/add-course': [
      {
        title: 'Добавление курса',
      },
    ],
    '/find-references': [
      {
        title: 'Конструктор',
      },
    ],
    '/users': [
      {
        title: 'Пользователи',
      },
    ],
    '/change-course': [
      {
        img: arrowBack,
        title: 'Изменение курса',
      },
    ],
    '/modules-admin': [
      {
        img: arrowBack,
        title: ' Модули ',
      },
    ],

    '/admin-groups': [
      {
        title: 'Группы',
      },
    ],
    '/out-user': [
      {
        title: 'Группы 1',
      },
    ],
  };

  function goToErrorPage() {
    navigate('error');
  }

  return (
    <div className="header">
      <h2 className="header-title">
        {titleObject['/' + path.split('/')[1]]
          ? titleObject['/' + path.split('/')[1]].map((item, i) => {
              return (
                <Fragment key={i}>
                  {item.img && (
                    <span className="header-title_img">
                      <img src={item.img} alt="arrowBack" onClick={() => navigate(-1)} />
                    </span>
                  )}
                  {item.title}
                </Fragment>
              );
            })
          : goToErrorPage()}
      </h2>
      <UserInfo />
    </div>
  );
};

export default Header;
