export function addStatusIcon(status) {
  switch (status) {
    case 'completed':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2 2.40002V8.40002H19.2"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3 15L5 17L9 13"
            stroke="#52C41A"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'current':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V7"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 2V8H20"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 17C5.39397 17 5.78407 16.9224 6.14805 16.7716C6.51203 16.6209 6.84274 16.3999 7.12132 16.1213C7.3999 15.8427 7.62087 15.512 7.77164 15.1481C7.9224 14.7841 8 14.394 8 14C8 13.606 7.9224 13.2159 7.77164 12.8519C7.62087 12.488 7.3999 12.1573 7.12132 11.8787C6.84274 11.6001 6.51203 11.3791 6.14805 11.2284C5.78407 11.0776 5.39397 11 5 11C4.20435 11 3.44129 11.3161 2.87868 11.8787C2.31607 12.4413 2 13.2044 2 14C2 14.7956 2.31607 15.5587 2.87868 16.1213C3.44129 16.6839 4.20435 17 5 17Z"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 18L7.5 16.5"
            stroke="#FBAD15"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case null:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 13.5V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H14.5L20 7.5V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H12.5"
            stroke="#5C5C5C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 2V8H20"
            stroke="#5C5C5C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.42 12.61C10.615 12.415 10.8465 12.2603 11.1013 12.1547C11.3561 12.0492 11.6292 11.9949 11.905 11.9949C12.1808 11.9949 12.4539 12.0492 12.7087 12.1547C12.9635 12.2603 13.195 12.415 13.39 12.61C13.585 12.805 13.7397 13.0365 13.8452 13.2913C13.9508 13.5461 14.0051 13.8192 14.0051 14.095C14.0051 14.3708 13.9508 14.6439 13.8452 14.8987C13.7397 15.1535 13.585 15.385 13.39 15.58L7.95 21L4 22L4.99 18.05L10.42 12.61Z"
            stroke="#5C5C5C"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      break;
  }
}