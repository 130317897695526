import React from 'react';
import './Input.scss';
import classNames from 'classnames';

const Input = ({
  type = 'text',
  inputRef,
  value,
  onChange,
  onKeyDown,
  className = '',
  placeholder,
  defaultValue,
  id,
}) => {
  let inputClass = classNames('input', `${className}`);
  return (
    <>
      {value ? (
        <input
          onChange={onChange}
          onKeyDown={onKeyDown}
          id={id}
          type={type}
          ref={inputRef}
          className={inputClass}
          placeholder={placeholder}
          value={value}
        />
      ) : (
        <input
          onChange={onChange}
          onKeyDown={onKeyDown}
          id={id}
          type={type}
          ref={inputRef}
          defaultValue={defaultValue}
          className={inputClass}
          placeholder={placeholder}
        />
      )}
    </>
  );
};

export default Input;
