import React from 'react';

import { userService } from '../../api/user';

import { Progress, Space } from 'antd';

const ProgressBar = () => {
  const [experience, setExperience] = React.useState([]);

  async function fetchExperince() {
    try {
      const data = await userService.getUserList();
      setExperience(data);
    } catch (err) {
      // console.log("Error:", err);
    }
  }
  React.useEffect(() => {
    fetchExperince();
  }, []);

  const progressValue = Math.min(
    (experience.experience / experience.next_level_experience) * 70,
    70,
  );

  return (
    <Space wrap>
      <Progress
        type="dashboard"
        percent={progressValue}
        size={130}
        strokeWidth={12}
        strokeColor="#FBAD15"
        trailColor="#F0F0F0"
        showInfo={false}
        style={{
          position: 'absolute',
          marginLeft: '-65px',
          marginTop: '-20px',
        }}
      />
    </Space>
  );
};

export default ProgressBar;
