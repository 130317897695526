import React from 'react';
import './SchoolChat.scss';
import search from '../../assets/img/icons/search.svg';
import smile from '../../assets/img/icons/smile.svg';
import mic from '../../assets/img/icons/mic.svg';
import Input from '../../UI/Input/Input';
import Textarea from '../../UI/Textarea/Textarea';
import UserList from './UserList/UserList';

const SchoolChat = () => {
  let chatBoard = React.useRef(null);
  const [value, setValue] = React.useState('');
  let inputRef = React.useRef(null);
  let flag = false;

  React.useEffect(() => {
    setScroll();
  }, []);

  function setScroll() {
    let height = 0;
    let messages = document.querySelectorAll('.messages');
    messages.forEach((elem) => {
      height += elem.clientHeight;
    });

    if (chatBoard.current.clientHeight - height > messages[0].clientHeight) {
      chatBoard.current.style.justifyContent = 'flex-end';
    } else {
      chatBoard.current.style.justifyContent = 'flex-start';
      let scrollCount = messages[messages.length - 1].clientHeight;
      chatBoard.current.scrollBy(0, scrollCount + 10);
    }
  }

  function sendMessage(e) {
    let p = document.createElement('p');
    p.classList.add('messages');
    p.innerHTML = value;
    chatBoard.current.append(p);
    e.target.value = '';
    setValue('');
    setScroll();
  }

  function onChange(e) {
    setValue(e.target.value);
  }

  return (
    <div className="school-chat">
      <div className="school-chat_columns">
        <div className="school-chat_column left-column">
          <label htmlFor="search">
            <img className="icon search" src={search} alt="search" />
            <Input id="search" type="text" placeholder="Поиск" />
          </label>
          <ul className="user-lists">
            <UserList active messageAlert />
            <UserList />
            <UserList messageAlert />
            <UserList messageAlert />
            <UserList />
            <UserList />
          </ul>
        </div>
        <div className="school-chat_column right-column">
          <div className="user-activity">
            <p className="user-activity_name">
              <span className="user-avatar">Т</span>
              <b>Алихан Евлоев</b>
            </p>
            <p className="user-activity_last-activity">был в сети 31 мар в 19:06</p>
          </div>
          <div ref={chatBoard} className="chat-board">
            <p className="messages">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae
              magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus
              aliquid! Perspiciatis inventore asperiores consequuntur repellendus.
              <br />
            </p>
            <p className="messages talker">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae
              magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus
              aliquid! Perspiciatis inventore asperiores consequuntur repellendus.
              <br />
            </p>
            <p className="messages">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae
              magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus
              aliquid! Perspiciatis inventore asperiores consequuntur repellendus.
              <br />
            </p>

            {/* <p className='messages'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus aliquid! Perspiciatis inventore asperiores consequuntur repellendus.<br/>
            </p>
            <p className='messages talker'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus aliquid! Perspiciatis inventore asperiores consequuntur repellendus.<br/>
            </p>
            <p className='messages'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, quos odit, beatae magnam voluptate iure sed natus illum sint tempore alias quibusdam, deleniti accusamus aliquid! Perspiciatis inventore asperiores consequuntur repellendus.<br/>
            </p> */}
          </div>
          <label className="message-input_label" htmlFor="chat-input">
            <div className="icons">
              <svg
                className="icon icon-image"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.49967 9.16665C8.42015 9.16665 9.16634 8.42045 9.16634 7.49998C9.16634 6.57951 8.42015 5.83331 7.49967 5.83331C6.5792 5.83331 5.83301 6.57951 5.83301 7.49998C5.83301 8.42045 6.5792 9.16665 7.49967 9.16665Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.5 12.5L14.9283 9.92835C14.6158 9.61589 14.1919 9.44037 13.75 9.44037C13.3081 9.44037 12.8842 9.61589 12.5717 9.92835L5 17.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <img className="icon icon-smile" src={smile} alt="chat-smile" />
              {value ? (
                <>
                  <svg
                    onClick={(e) => sendMessage(e)}
                    className="icon icon-send"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 20L20 9.99948L0 0L0.00969209 8.1328L12.0129 9.99948L0.00969209 11.8672L0 20Z"
                      fill="#5C5C5C"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <img className="icon icon-mic" src={mic} alt="chat-mic" />
                </>
              )}
            </div>

            {/* <Input inputRef={inputRef} value={value} onChange={onChange} onKeyDown={e => sendMessage(e)} id='chatInput' type='text' placeholder='Введите сообщение' /> */}
            <Textarea
              inputRef={inputRef}
              value={value}
              onChange={onChange}
              // onKeyDown={e => sendMessage(e)}
              placeholder="Введите сообщение"></Textarea>
          </label>
        </div>
      </div>
    </div>
  );
};

export default SchoolChat;

{
  /* <svg className='icon icon-image' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.49967 9.16665C8.42015 9.16665 9.16634 8.42045 9.16634 7.49998C9.16634 6.57951 8.42015 5.83331 7.49967 5.83331C6.5792 5.83331 5.83301 6.57951 5.83301 7.49998C5.83301 8.42045 6.5792 9.16665 7.49967 9.16665Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.5 12.5L14.9283 9.92835C14.6158 9.61589 14.1919 9.44037 13.75 9.44037C13.3081 9.44037 12.8842 9.61589 12.5717 9.92835L5 17.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
<img className='icon icon-smile' src={smile} alt="chat-smile" />
{
value ? <>
  <svg onClick={e => sendMessage(e)} className='icon icon-send' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 20L20 9.99948L0 0L0.00969209 8.1328L12.0129 9.99948L0.00969209 11.8672L0 20Z" fill="#5C5C5C"/>
  </svg>
</> : <>
  <img className='icon icon-mic' src={mic} alt="chat-mic" />
</>
} */
}
