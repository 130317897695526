import React, { useState, useEffect, useRef } from 'react';
import './CustomSelector.scss';

import succes from '../../assets/img/magazineJournal/succes.svg';
import error from '../../assets/img/magazineJournal/errorMagazine.svg';
import notGoing from '../../assets/img/magazineJournal/notGoing.svg';
import checked from '../../assets/img/magazineJournal/checked.svg';
import taskVisible from '../.././assets/img/scroll-text.svg';
import arrow from '../../assets/img/icons/arrow.svg';

import succesMagazine from '../../assets/img/attendenceJournal/succesMagazine.svg';
import errorMagazine from '../../assets/img/attendenceJournal/errorMagazine.svg';
import notCompleted from '../../assets/img/attendenceJournal/notCompleted.svg';
import checkedMagazine from '../../assets/img/attendenceJournal/checkedMagazine.svg';
import { Modal } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../api/ApiService';

import { convertDataToJSX } from '../../components/TaskEditor/configuration.utils';

const CustomSelector = ({ status, onChange, magazineType, taskId, themeId, userId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [responseTitle, setResponseTitle] = useState('');
  const [responseTask, setResponseTask] = useState([]);
  const [studentTask, setStudentTask] = useState([]);
  const [visibleTask, setVisibleTask] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const initialState =
    magazineType && magazineType.label === 'Проверка заданий' ? 'Не выполнено' : 'Не выбрано';
  const initialStateIcon =
    magazineType && magazineType.label === 'Проверка заданий' ? notCompleted : error;
  const fetchResponseTask = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}/api/v1/studentTasks/${userId}/${themeId}/${taskId}`,
      );
      const response = await axios.get(`${apiUrl}/api/v1/tasks/task/${taskId}`);

      setStudentTask(JSON.parse(response.data.content).blocks);
      setResponseTitle(response.data.title);
      setResponseTask(data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    if (!isModalVisible) {
      fetchResponseTask();
    }
  };
  const onVisibleTask = () => {
    setVisibleTask(!visibleTask);
  };

  const [selectedOption, setSelectedOption] = useState({
    name: initialState,
    image: initialStateIcon,
    background: '#FFFFFF',
  });

  const optionsAttendence = [
    {
      status: 'visited',
      name: 'Посетил',
      image: succes,
      background: '#F2FFEC',
    },
    {
      status: 'notVisited',

      name: 'Не посетил',
      image: notGoing,
      background: '#FFF5F5',
    },
    {
      status: null,
      name: 'Не выбрано',
      image: error,
      background: '#FFFFFF',
    },
    {
      status: 'reason',
      name: 'Уважительная причина',
      image: checked,
      background: '#FFF8F2',
    },
  ];

  const optionsMagazine = [
    {
      status: 'completed',
      name: 'Выполнено',
      image: succesMagazine,
      background: '#F2FFEC',
    },
    {
      status: 'wrong',
      name: 'Ошибка',
      image: errorMagazine,
      background: '#FFF5F5',
    },
    {
      status: null,
      name: 'Не выполнено',
      image: notCompleted,
      background: '#FFFFFF',
    },
    {
      status: 'check',
      name: 'На проверке',
      image: checkedMagazine,
      background: '#FFF8F2',
    },
  ];
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option.status, option.task_id, option.lessonId);
  };
  const selectorRef = useRef(null);
  const options = magazineType && magazineType.label ? optionsMagazine : optionsAttendence;

  useEffect(() => {
    const selected = options.find((option) => option.status === status);
    if (selected) {
      setSelectedOption(selected);
    }
  }, [status]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={selectorRef} className={`custom-selector`} onClick={toggleOptions}>
      <div className="selected-option" style={{ background: selectedOption.background }}>
        <img src={selectedOption.image} alt={selectedOption.name} />
        <span>{selectedOption.name}</span>
        {magazineType &&
          magazineType.label !== 'Посещаемость' &&
          selectedOption.name !== 'Не выполнено' && (
            <div className="taskVisible" onClick={toggleModal}>
              <img style={{ marginLeft: '10px' }} src={taskVisible} />
            </div>
          )}

        <div className="svg-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none">
            <path
              d="M5.33325 7.5L10.3333 12.5L15.3333 7.5"
              stroke="#5C5C5C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="options">
          {options.map((option) => (
            <div
              key={option.name}
              className="option"
              onClick={() => handleOptionClick(option)}
              style={{ background: option.background }}>
              <img src={option.image} alt={option.name} />
              <span>{option.name}</span>
            </div>
          ))}
        </div>
      )}
      <Modal
        width={'740px'}
        title="Ответ на задание"
        visible={isModalVisible}
        onCancel={toggleModal}
        footer={null}>
        <div>
          <div className="response_task" width={'100%'}>
            {responseTask.answer_content ? (
              <p>{responseTask.answer_content}</p>
            ) : (
              <p>Пока ответа нету</p>
            )}
          </div>
          <br />
          <div className="select_task" onClick={onVisibleTask}>
            <span className="span">
              <p className="title_task"> {responseTitle || <p>Пока нету задания</p>} </p>
              <img src={arrow} alt="arrow" className={visibleTask ? 'active_arrow' : ''} />
            </span>
            {visibleTask ? (
              <div className="courses-head">
                {studentTask?.content === null && <h1>Пустое задание, выберите другое....</h1>}
                {studentTask && studentTask.map((block) => convertDataToJSX(block))}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CustomSelector;
