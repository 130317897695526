import axios from "../core/axios";

export const apiUrl = "https://school.code06.ru";

export class ApiService {
  getListUserRating() {
    let response = [];

    axios.get(`${apiUrl}/api/v1/users`).then((data) => {
      response = data.data(data.data.data);
    });

    return response;
  }
}
