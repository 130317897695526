import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../api/ApiService";

export const updateModuleDesc = createAsyncThunk("update/updateModuleDesc", async ({ id, title, order }, thunkAPI) => {

  const token = localStorage.getItem('token');
  console.log(token);
  try {
    const { data } = await axios.put(`${apiUrl}/api/v1/modules?_method=put`, {
      id, title, order
    }, {
      headers: {
        Authorization: `Bearer` + token
      }
    })
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error)
    }

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }

})
const updateModuleDescriptionEditorSlice = createSlice({
  name: 'updateModuleDescriptionEditor',
  initialState: {
    title: '',
  },
  reducers: {
    setDescription(state, { payload }) {

      state.title = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateModuleDesc.fulfilled, (state, { payload }) => {
      state.title = payload.title
    })
  }
})
export const { setDescription } = updateModuleDescriptionEditorSlice.actions

export default updateModuleDescriptionEditorSlice.reducer