import React from 'react';
import './Select.scss';

const Select = ({ children, value, onChange }) => {
  return (
    <select className="select" onChange={onChange} value={value}>
      {children}
    </select>
  );
};

export default Select;
