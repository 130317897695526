import React from 'react';
import './AddNewUser.scss';
import axios from 'axios';
import { apiUrl } from '../../api/ApiService';

import Switch from '../../UI/Switch/Switch';
import hideEye from '../../assets/img/icons/hideEye.svg';
import eye from '../../assets/img/icons/eye 2.svg';
import arrow from '../../assets/img/icons/arrow.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { message } from 'antd';

const AddNewUser = () => {
  const location = useLocation();
  const token = localStorage.getItem('token');

  const [avatarUrl, setAvatarUrl] = React.useState(null);
  const formRef = React.useRef(null);
  const [notifyGrades, setNotifyGrades] = React.useState(false);
  const [notifyVisits, setNotifyVisits] = React.useState(false);
  const [selectActive, setSelectActive] = React.useState(false);
  const [selectActiveSchools, setSelectActiveSchools] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    password: false,
    passwordConfirmation: false,
  });
  let arrowLink = React.useRef(null);
  let arrowLinkSchool = React.useRef(null);
  const [schools, setSchools] = React.useState([]);
  const navigate = useNavigate();
  const [errorArr, setErrorArr] = React.useState([]);
  const [roleArray, setRoleArray] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/api/v1/users/roles`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      })
      .then((d) => {
        setRoleArray(d.data);
      });
  }, []);

  React.useEffect(() => {
    axios
      .get(`${apiUrl}/api/v1/schools`, {
        headers: {
          Authorization: `Bearer ` + token,
        },
      })
      .then((d) => {
        setSchools(d.data);
      });
  }, []);

  function changeAvatarAdd(e) {
    const file = e.target.files[0];
    if (file) {
      setAvatarUrl(URL.createObjectURL(file));
    }
  }

  function changeAvatarRemove(e) {
    setAvatarUrl(null);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setTimeout(() => {
      const token = localStorage.getItem('token');

      const form = new FormData(formRef.current);

      axios
        .post(`${apiUrl}/api/v1/users`, form, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          message.success('Пользователь успешно добавлен');
          console.log('User added successfully:', response.data);
          navigate('/users');
        })
        .catch((error) => {
          console.error('Error adding user:', error);
          console.log(error.response.data.data);
          setErrorArr(error.response.data.data);
        });
    }, 100);
  };

  function showPasswordFunction(typePassword) {
    if (typePassword === 'password') {
      setShowPassword({ ...showPassword, password: !showPassword.password });
    } else if (typePassword === 'passwordConfirmation') {
      setShowPassword({
        ...showPassword,
        passwordConfirmation: !showPassword.passwordConfirmation,
      });
    }
  }

  return (
    <div className="settings">
      <form onSubmit={(e) => onSubmit(e)} ref={formRef}>
        <div className="settings_table">
          <h2>Общее</h2>
          <div className="upload">
            <div className="block_photo">
              <label htmlFor="avatar_avatarInput">
                {avatarUrl ? (
                  <Avatar width3>
                    <img className="img-upload" src={avatarUrl} alt="Avatar" />
                  </Avatar>
                ) : (
                  <Avatar width3>
                    <p className="name-avatar">C</p>
                  </Avatar>
                )}
              </label>
              <input
                type="file"
                onChange={(e) => changeAvatarAdd(e)}
                name="image"
                id="avatar_avatarInput"
                style={{ display: 'none' }}
              />
            </div>

            <label htmlFor="avatar_avatarInput" className="download">
              Загрузить фото
            </label>

            <button onClick={(e) => changeAvatarRemove()} className="remove">
              Удалить
            </button>
          </div>

          <div className="parts">
            <div className="part">
              <label htmlFor="lastName">Фамилия</label>
              <br />

              <input
                className="input"
                name="last_name"
                type="text"
                placeholder="Фамилия"
                id="last_name"
              />
              {errorArr.last_name && <p className="validation-error">{errorArr.last_name}</p>}
            </div>

            <div className="part">
              <label htmlFor="first_name">Имя</label>
              <br />
              <input
                className="input"
                name="first_name"
                type="text"
                placeholder="Имя"
                id="first_name"
              />
              {errorArr.first_name && <p className="validation-error">{errorArr.first_name}</p>}
            </div>

            <div className="part">
              <label htmlFor="lastName">Отчество</label>
              <br />
              <input
                className="input"
                name="patronymic"
                type="text"
                placeholder="Отчество"
                id="patronymic"
              />
              {errorArr.patronymic && <p className="validation-error">{errorArr.patronymic}</p>}
            </div>
          </div>

          <div className="role-school">
            <div className="role">
              <label htmlFor="first_name">Роль</label>
              <br />
              <select
                onClick={() => setSelectActive(!selectActive)}
                onBlur={() => setSelectActive(false)}
                name="role"
                id="role">
                {roleArray.map((item, i) => {
                  return (
                    <option key={i} value={item.name}>
                      {item.nameRu}
                    </option>
                  );
                })}
              </select>
              <img
                ref={arrowLink}
                src={arrow}
                style={{ transform: selectActive ? 'rotate(180deg)' : 'rotate(0deg)' }}
                alt="arrow"
              />
            </div>

            <div className="school">
              <label htmlFor="first_name">Школа</label>
              <br />
              <select
                onClick={() => setSelectActiveSchools(!selectActiveSchools)}
                onBlur={() => setSelectActiveSchools(false)}
                name="school_id"
                id="school">
                {schools.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              <img
                ref={arrowLinkSchool}
                src={arrow}
                style={{ transform: selectActiveSchools ? 'rotate(180deg)' : 'rotate(0deg)' }}
                alt="arrow"
              />
            </div>
          </div>

          <div className="textarea">
            <label htmlFor="about">О себе</label>
            <textarea
              style={{ fontFamily: 'Nunito Sans' }}
              id="about"
              name="description"
              placeholder="Текст"></textarea>
          </div>

          <div className="contact">
            <h2>Контакты и безопасность</h2>
            <div className="password">
              <div style={{ width: '100%', position: 'relative' }}>
                <label htmlFor="new_password">
                  {location.pathname === '/add-user' ? 'Пароль' : 'Новый пароль'}
                </label>
                <br />
                <input
                  className="input"
                  type={showPassword.password ? 'text' : 'password'}
                  placeholder="Новый пароль"
                  id="new_password"
                  name="password"
                />

                <img
                  onClick={() => showPasswordFunction('password')}
                  className="icon eye"
                  src={showPassword.password ? hideEye : eye}
                  alt="eye"
                />
                {errorArr.password && <p className="validation-error">{errorArr.password}</p>}
              </div>

              <div style={{ width: '100%', position: 'relative' }}>
                <label htmlFor="password_confirmation">Подтверждение пароля</label>
                <br />
                <input
                  className="input"
                  type={showPassword.passwordConfirmation ? 'text' : 'password'}
                  placeholder="Подтверждение пароля"
                  id="password_confirmation"
                  name="password_confirmation"
                />

                <img
                  onClick={() => showPasswordFunction('passwordConfirmation')}
                  className="icon eye"
                  src={showPassword.passwordConfirmation ? hideEye : eye}
                  alt="eye"
                />
                {errorArr.password_confirmation && (
                  <p className="validation-error">{errorArr.password_confirmation}</p>
                )}
              </div>
            </div>

            <div className="number_email">
              <div style={{ width: '100%' }}>
                <label htmlFor="phone_number">Номер</label>
                <br />
                <input
                  className="input"
                  type="tel"
                  name="phone_number"
                  placeholder="+7 (928) 321 22 22"
                  id="phone_number"
                  pattern="\+7 \(\d{3}\) \d{3} \d{2} \d{2}"
                />
                {errorArr.phone_number && (
                  <p className="validation-error">{errorArr.phone_number}</p>
                )}
              </div>
              <div style={{ width: '100%' }}>
                <label htmlFor="email">Почта</label>
                <br />
                <input
                  className="input"
                  name="email"
                  type="text"
                  placeholder="ya@mail.ru"
                  id="email"
                />
                {errorArr.email && <p className="validation-error">{errorArr.email}</p>}
              </div>
            </div>
          </div>

          <div>
            <div style={{ display: 'flex', marginTop: '22px' }}>
              <button className="add-user">Добавить пользователя</button>
              <Link style={{ textDecoration: 'none' }} to="/users">
                <button className="cancel">Отмена</button>
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewUser;
