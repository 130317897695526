import React from 'react';

const Textarea = ({
  children,
  placeholder,
  inputRef,
  value,
  onChange,
  onKeyDown,
  id,
  defaultValue,
}) => {
  return (
    <textarea
      style={{ fontFamily: 'Nunito Sans' }}
      ref={inputRef}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      defaultValue={defaultValue}
      onKeyDown={onKeyDown}>
      {children}
    </textarea>
  );
};

export default Textarea;
