import React from 'react';
import './FAQ.scss'
import FaqAccordion from './FaqAccordion/FaqAccordion';

const FAQ = () => {

  let questionArray = [
    {
      question: 'Как начать курс?',
      answer: ['Lorem ipsum dolor sit amet consectetur. Sed at egestas sit justo enim iaculis. Tincidunt risus pellentesque lacus faucibus tincidunt. Senectus arcu sed tellus dolor nulla.']
    },
    {
      question: 'Как регистрироваться на сайте?',
      answer: ['Lorem ipsum dolor sit amet consectetur. Sed at egestas sit justo enim iaculis. Tincidunt risus pellentesque lacus faucibus tincidunt. Senectus arcu sed tellus dolor nulla.']
    },
    {
      question: 'Как регистрироваться на сайте?',
      answer: ['Lorem ipsum dolor sit amet consectetur. Sed at egestas sit justo enim iaculis. Tincidunt risus pellentesque lacus faucibus tincidunt. Senectus arcu sed tellus dolor nulla.']
    },
    {
      question: 'Как регистрироваться на сайте?',
      answer: ['Lorem ipsum dolor sit amet consectetur. Sed at egestas sit justo enim iaculis. Tincidunt risus pellentesque lacus faucibus tincidunt. Senectus arcu sed tellus dolor nulla.']
    },
  ]
  
  return (
    <div className='faq'>
      <h1 className='faq_title'>Общие вопросы</h1>
      <div className="faq-accordions">
        {
          questionArray.length && questionArray.map((obj, i) => {
            return <FaqAccordion key={i} obj={obj} />
          })
        }
      </div>
    </div>
  );
};

export default FAQ;