import React from 'react';
import './OutTab.scss';
import TabCard from './TabCard/TabCard';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupModules } from '../../../redux/courses/tabCard';

const OutTab = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const cardActiveArray = useSelector((state) => state.tabCard.progress);

  React.useEffect(() => {
    dispatch(getGroupModules({ id: id }));
  }, [dispatch]);

  return (
    <div className="out-tab">
      {cardActiveArray?.map((item, index) => {
        return <TabCard module_id={item.module_id} obj={item} key={index} />;
      })}
    </div>
  );
};

export default OutTab;
