import { configureStore } from "@reduxjs/toolkit";
import coursesReducer from "./courses/courses";
import outTabReducer from "./courses/outTab";
import headerReducer from "./header/header";
import tabCardReducer from "./courses/tabCard";
import ratingReducer from "./rating";
import shopReducer from "./shop";
import editorReducer from './editor/editor.slice'
import siteMapSlice from "./courses/siteMap/site-map.slice";
import modulesListSlice from './module/module.slice'
import themesCoursesSlise from "./themeCourse/themesCourses";
import updateModuleDescriptionEditorSlice from './updateModuleEditor'



const store = configureStore({
  reducer: {
    courses: coursesReducer,
    header: headerReducer,
    outTab: outTabReducer,
    tabCard: tabCardReducer,
    shop: shopReducer,
    rating: ratingReducer,
    editor: editorReducer,
    jobsOutput: siteMapSlice,
    modulesList: modulesListSlice,
    themesCourses: themesCoursesSlise,
    descModuleInEditor: updateModuleDescriptionEditorSlice




  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        isSerializable: () => true,
      },
    }),
  ]
});

export default store;
