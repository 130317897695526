import classNames from 'classnames';
import arrow from '../../.././assets/img/icons/arrow.svg';
import React from 'react';
import axios from 'axios';
import { apiUrl } from '../../../api/ApiService';

import { addStatusIcon } from './addStatusIcon';

import './AccordionBody.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setTasks } from '../../../redux/courses/siteMap/site-map.slice';

const AccordionBody = ({ obj, onTaskClick }) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const [active, setActive] = React.useState(false);
  const [isActiveTask, setIsActiveTask] = React.useState(null);
  const { tasks } = useSelector((state) => state.jobsOutput);

  const handleActive = () => {
    setActive(!active);
  };

  React.useEffect(() => {
    dispatch(setTasks(null));
    const fetchTask = async () => {
      if (!obj.id) return;
      try {
        const { data } = await axios.get(`${apiUrl}/api/v1/studentTasks/${obj.id}`, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
        dispatch(setTasks(data));
      } catch (error) {
        console.log('Error:', error);
      }
    };
    fetchTask();
  }, []);

  return (
    <div className={classNames('accordion', { active: active })}>
      <div className="contentContainer" onClick={handleActive}>
        <div className="img">
          <img className="arrow" src={arrow} alt="arrow" />
        </div>
        <span>{obj.title}</span>
      </div>

      <div className="sublists">
        {tasks?.map((task) => {
          return (
            task.theme_id === obj.theme_id && (
              <p
                onClick={() => {
                  onTaskClick(obj.id, task.id, obj.status);
                  setIsActiveTask(task.id);
                }}
                key={task.id}
                className={`sublist ${isActiveTask === task?.id && 'active'}`}>
                {task.title}
                <div style={{ marginLeft: '10px ' }}>{addStatusIcon(task.status)}</div>
              </p>
            )
          );
        })}
      </div>
    </div>
  );
};

export default AccordionBody;
