import React from 'react';
import Menu from './pages/Menu/Menu';
import './App.scss';
import AppRoutes from './routes/AppRoutes';
import { Auth } from './context/context';

import { Route, Routes, useLocation } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage/ErrorPage';

function App() {
  const [isAuth, setIsAuth] = React.useState(false);
  const token = localStorage.getItem('token');
  const location = useLocation();

  React.useEffect(() => {
    function getNewToken() {
      if (Boolean(token)) {
        setIsAuth(true);
      }
    }
    getNewToken();
  }, []);

  return (
    <Auth.Provider
      value={{
        value: isAuth,
        setIsAuth: setIsAuth,
      }}>
      <div className="app">
        {location.pathname == '/error' ? (
          <ErrorPage />
        ) : (
          <>
            {isAuth && <Menu />}
            <div className="container">
              <div style={{ width: '100%' }}>
                <AppRoutes />
              </div>
            </div>
          </>
        )}
      </div>
    </Auth.Provider>
  );
}

export default App;
