import React from 'react';
import './CourseCard.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const CourseCard = ({ obj }) => {
  const interest = Math.floor((obj.completed_total / obj.module_quantity) * 100);

  const [avatarColors, setAvatarColors] = React.useState({});

  React.useEffect(() => {
    const storedColors = JSON.parse(localStorage.getItem('avatarColors')) || {};
    setAvatarColors(storedColors);
  }, []);

  React.useEffect(() => {
    localStorage.setItem('avatarColors', JSON.stringify(avatarColors));
  }, [avatarColors]);

  function addClass(index) {
    if (index < obj.completed_total) {
      return `module-count_span module-count_span passed`;
    } else if (index === obj.completed_total) {
      return `module-count_span module-count_span current`;
    } else {
      return `module-count_span module-count_span`;
    }
  }

  function getAvatarColor(studentId) {
    if (avatarColors[studentId]) {
      return avatarColors[studentId];
    } else {
      const randomColor = getRandomColor();
      setAvatarColors((prevColors) => ({
        ...prevColors,
        [studentId]: randomColor,
      }));
      return randomColor;
    }
  }

  return (
    <div className="new-card">
      <svg
        className="svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Link to={`/courses/${obj.id}`} className="card-link">
        <div className={classNames('course-card', { favorite: false })}>
          <h2 className="course-card_title">Курс {obj.short_name.slice(0, 18)}</h2>

          <p className="course-card_description">{obj.full_name.slice(0, 29)}...</p>
          <div className="course-card_participants">
            <ul className="course-card_participants-lists">
              {obj.students.length &&
                obj.students.slice(0, 5).map((student) => {
                  const listItemStyle = {
                    backgroundColor: getAvatarColor(student.id),
                  };
                  return (
                    <li
                      key={student.id}
                      className="course-card_participants-list"
                      style={listItemStyle}>
                      {student.name.slice(0, 1)}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="course-card_progress">
            <div className="course-card_progress-diagram">
              {new Array(obj.module_quantity + 1).fill(0).map((obj, index) => {
                return <span className={addClass(index)} key={index}></span>;
              })}
            </div>
            <p className="course-card_progress-description">
              <span className="module-count">{obj.module_quantity} модулей</span>
              <span className="prosent">
                <span>{interest}</span>%
              </span>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CourseCard;
