import React from "react";
import "./CardModal.scss";
import deleteCart from "../../assets/img/icons/cart.svg";
import Button from "../../UI/Button/Button";
import hudi from "../../assets/img/front.jpg";
import hackcoins from "../../assets/img/icons/hackcoins.svg";
import ProductList from "./ProductList/ProductList";

const CardModal = () => {
  return (
    <div className="card-modal">
      <div className="card-modal_title">
        <span>Корзина</span>

        <div className="btn_delete-select">
          <img src={deleteCart} alt="deleteCart" />
          Удалить выделенное
        </div>
      </div>

      <div className="card-modal_product-lists">
        <ProductList>
          <input
            id="select-product"
            className="select-product"
            type="checkbox"
          />
          <label htmlFor="select-product"></label>
          <div className="product-img">
            <img src={hudi} alt="hudi" />
          </div>
          <p className="product-name">
            <p className="product-name_title">Худи "Код будущего + AIS"</p>
            <p className="product-name_description">
              Кол-во на складе: <span>250</span> шт.
            </p>
          </p>
          <div className="product-price">
            <p className="total-price">
              <img src={hackcoins} alt="hackcoins" />
              <span>30</span>
            </p>

            <p className="one-thing_cost">
              <span>1х -</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_174_5361)">
                  <path
                    d="M11.3333 9.26681V4.73254C11.3331 4.53376 11.2816 4.33852 11.1841 4.16642C11.0866 3.99432 10.9464 3.8514 10.7777 3.75201L6.88881 1.48488C6.7199 1.38539 6.52829 1.33301 6.33325 1.33301C6.13821 1.33301 5.94661 1.38539 5.7777 1.48488L1.88881 3.75201C1.72006 3.8514 1.57991 3.99432 1.4824 4.16642C1.38489 4.33852 1.33345 4.53376 1.33325 4.73254V9.26681C1.33345 9.46559 1.38489 9.66083 1.4824 9.83293C1.57991 10.005 1.72006 10.1479 1.88881 10.2473L5.7777 12.5145C5.94661 12.614 6.13821 12.6663 6.33325 12.6663C6.52829 12.6663 6.7199 12.614 6.88881 12.5145L10.7777 10.2473C10.9464 10.1479 11.0866 10.005 11.1841 9.83293C11.2816 9.66083 11.3331 9.46559 11.3333 9.26681Z"
                    stroke="#B7B7B7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.3444 5.30566L14.1112 5.75268C14.2799 5.85208 14.4201 5.99499 14.5176 6.16709C14.6151 6.3392 14.6665 6.53443 14.6667 6.73322V11.2675C14.6665 11.4663 14.6151 11.6615 14.5176 11.8336C14.4201 12.0057 14.2799 12.1486 14.1112 12.248L10.2223 14.5151C10.0534 14.6146 9.86178 14.667 9.66674 14.667C9.4717 14.667 9.28009 14.6146 9.11118 14.5151L8.31445 14.0507"
                    stroke="#B7B7B7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.66675 5.33301L8.00008 8.66634"
                    stroke="#B7B7B7"
                    strokeLinecap="round"
                  />
                  <path
                    d="M8 5.33301L4.66667 8.66634"
                    stroke="#B7B7B7"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_174_5361">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>15</span>
            </p>
          </div>
          <div className="product-count">
            <div className="decrement">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.33325 8H12.6666"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p className="product-count_number">{2}</p>
            <div className="increment">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 3.33301V12.6663"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.33325 8H12.6666"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="product-remove">
            <img src={deleteCart} alt="deleteCart" />
          </div>
        </ProductList>
      </div>

      <Button buttonColor="green">153 Оформить все</Button>
    </div>
  );
};

export default CardModal;
