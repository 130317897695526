import React, { forwardRef, useState } from 'react';
import Arrow_right from '../../assets/img/icons/Arrow_right.svg';

import './accordion.scss';
import classNames from 'classnames';

const Accordion = forwardRef(
  (
    {
      id,
      children,
      className,
      title,
      isOpened,
      onToggle,
      rightSectionMove,
      rightSectionDelete,
      rightSectionEdit,
      isTitleEdit,
      onChangeTitle,
      ...rest
    },
    ref,
  ) => {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);

    return (
      <div className={classNames('accordion', className)} {...rest}>
        <div
          className={classNames('accordionHeader')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div onClick={onToggle} className="accordionHeaderContent">
            <div
              className={classNames('accordionHeaderContentArrowDown', {
                isActive: isOpened,
              })}>
              <img src={Arrow_right} alt="arrowdDown" />
            </div>
            {isTitleEdit ? (
              <input
                autoFocus
                className="titleEditInput"
                value={title}
                onChange={(event) => onChangeTitle(event, id)}
              />
            ) : (
              <h2 className="accordionHeaderTitle">
                <div div className="accordionHeaderTitleText">
                  {title}
                </div>
              </h2>
            )}
          </div>

          <div className={classNames('rightSections', { hovered: isHover })} ref={ref}>
            {rightSectionEdit && rightSectionEdit}
            {rightSectionDelete && rightSectionDelete}
            {rightSectionMove && rightSectionMove}
          </div>
        </div>
        <div className="accordionContentSlideDown">
          {isOpened && (
            <div className="accordionContent">
              <span>{children}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
);
export default Accordion;
