import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
import { apiUrl } from "../../api/ApiService";


export const getModulesList = createAsyncThunk('get/getModulesList', async ({ id }, thunkAPI) => {
  if (id === undefined) return


  const token = localStorage.getItem('token');


  try {
    const { data } = await axios.get(`${apiUrl}/api/v1/modules/${id}`, {
      headers: {
        Authorization: `Bearer` + token

      }
    });
    if (data.error) {
      return thunkAPI.rejectWithValue(data.error);
    }

    return data
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const modulesListSlice = createSlice({
  name: 'modulesList',
  initialState: {
    modulesList: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getModulesList.fulfilled, (state, { payload }) => {
      state.modulesList = payload
    })
  }
})

export default modulesListSlice.reducer